.user-games-collection{
    min-height: 100vh;
    width: 98%;
    padding: 1%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* overflow-y: auto; */
}

.user-games-container{
    height: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.game-collection-actions{
    height: auto;
    width: 100%;
    padding: 1%;
    margin-bottom: 7px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* position: fixed; */
    /* right: 20px;
    top: 41vh; */
    z-index: 999;
}

.no-user-games-prompt{
    height: 85vh;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.no-user-games-prompt p{
    background-color: #FFE74C;
    padding: 2%;
    color: #080808;
    font-size: 0.95rem;
    font-weight: 700;
}

.add-game-to-collection-cta{
    /* height: 35px;
    width: 150px; */
    height: auto;
    width: auto;
    padding: 0.75%;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 5px 5px 5px 5px;
    background-color: #FFE74C;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #080808;
    font-size: 0.95rem;
    font-weight: 700;
    cursor: pointer;
}

.like-game-collection-cta{
    height: auto;
    width: auto;
    padding: 0.5%;
    padding-left: 1%;
    padding-right: 1%;
    margin: 5px;
    background-color: #FFE74C;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .user-games-collection{
        min-height: 100vh;
        width: 98%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        /* overflow-y: auto; */
    }
    .user-games-container{
        height: 90%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .game-collection-actions{
        height: 50px;
        width: 100%;
        padding: 1%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        /* position: fixed;
        top: 80vh; */
        z-index: 999;
    }

    .add-game-to-collection-cta{
        height: auto;
        width: auto;
        padding: 0.75%;
        padding-left: 2%;
        padding-right: 2%;
        border: none;
        outline: none;
        border-radius: 5px 5px 5px 5px;
        background-color: #FFE74C;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #080808;
        font-size: 0.95rem;
        font-weight: 700;
        cursor: pointer;
    }

    .like-game-collection-cta{
        height: auto;
        width: auto;
        padding: 0.5%;
        padding-left: 1%;
        padding-right: 1%;
        /* margin: 5px; */
        background-color: #FFE74C;
        border-radius: 5px 5px 5px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}