.report-page-container{
    height: 90.95vh;
    width: 100%;
    background-color: #080808;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.report-page-container p{
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    color: #FFE74C;
    font-size: 28px;
    font-weight: 500;
}

.picture-container{
    height: 300px;
    width: 300px;
    border-radius: 10px 10px 10px 10px;
    background-color: #191917;
}
.picture-container img{
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 10px 10px;
    object-fit: cover;
}

.reason-input{
    height: 45px;
    width: 50%;
    padding: 0.5%;
    border: none;
    outline: none;
    border-radius: 5px 5px 5px 5px;
    background-color: #191917;
    color: #f8f8f8;
    font-size: 16px;
}

.report-item-cta{
    height: 35px;
    width: 100px;
    padding: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    border: none;
    background-color: #FFE74C;
    color: #191917;
    font-size: 14px;
    font-weight: 600;
}
.report-item-cta:hover{
    background-color: #feec79;
    cursor: pointer;
}

.report-form{
    height: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.report-preview{
    height: 500px;
    width: 350px;
    background-color: #f8f8f8;
}