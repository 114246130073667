.external-auth-provider-container{
    height: auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.external-auth-provider-heading{
    width: 50%;
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 7px;
    color: #f8f8f8;
    text-align: left;
}

.user-login-input-form{
    height: auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.auth-form-divider{
    height: 10px;
    width: 50%;
    padding-top: 2%;
    padding-bottom: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    color: #cacaca;
}

.email-input{
    height: 30px;
    width: 95%;
    padding: 1%;
    margin-bottom: 20px;
    background-color: transparent;
    border: solid #EEE5E9;
    border-radius: 10px 10px 10px 10px;
    outline: none;
    color: #f8f8f8;
    font-size: medium;
    font-weight: 500;
}

.email-link-auth-area{
    height: auto;
    width: 50%;
    box-sizing: border-box;
    margin-top: 10px;
    display: flex;
    gap: 7.5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sending-email-feedback{
    margin: 0;
    margin-top: 7.5px;
    padding: 0;
    font-size: 0.95rem;
    font-weight: 600;
    color: #f8f8f8;
    text-align: center;
}

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .external-auth-provider-container{
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    .external-auth-provider-heading{
        width: 90%;
        text-align: center;
    }
    .email-link-auth-area{
        width: 90%;
    }
}
/* FOR MOBILE DEVICES ONLY */