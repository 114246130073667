.notification-tile{
    height: auto;
    width: 100%;
    padding: 2%;
    margin: 5px;
    background-color: #191917;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
}

.notification-sender-pfp{
    height: 40px;
    width: 40px;
    margin-right: 7px;
    border-radius: 50% 50% 50% 50%;
    background-color: #191917;
    display: flex;
    justify-content: center;
    align-items: center;
}
.notification-sender-pfp img{
    height: 100%;
    width: 100%;
    border-radius: 50% 50% 50% 50%;
    object-fit: cover;
}
.notification-sender-pfp p{
    font-size: 0.8rem;
    font-weight: 700;
    color: #FFE74C;
}

.notification-content{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.notification-title{
    width: 100%;
    margin-bottom: 7px;
    text-align: left;
    font-size: 0.7rem;
    font-weight: 600;
    color: #f8f8f8;
}
.notification-date{
    text-align: left;
    color: #aeaeae;
    font-size: 0.8rem;
    font-weight: 400;
}
.sender-name{
    text-align: left;
    font-size: 0.8rem;
    font-weight: 700;
    color: #FFE74C;
}

.notification-body{
    width: 100%;
    margin-bottom: 7px;
    text-align: left;
    font-size: 0.7rem;
    font-weight: 600;
    color: #f8f8f8;
}

.action-on{
    font-size: 0.8rem;
    font-weight: 800;
    color: #f8f8f8;
}

.notification-actions{
    height: auto;
    padding: 1%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.read-btn-cta{
    margin: 3px;
    border: none;
    outline: none;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.8rem;
    font-weight: 700;
} 

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
    .notification-title{
        width: 100%;
        margin-bottom: 7px;
        text-align: left;
        font-size: 0.85rem;
        font-weight: 600;
        color: #f8f8f8;
    }
    .sender-name{
        text-align: left;
        font-size: 0.9rem;
        font-weight: 700;
        color: #FFE74C;
    }
    
    .notification-body{
        width: 100%;
        margin-bottom: 7px;
        text-align: left;
        font-size: 0.80rem;
        font-weight: 600;
        color: #f8f8f8;
    }
    
    .action-on{
        font-size: 0.89rem;
        font-weight: 800;
        color: #f8f8f8;
    }
}