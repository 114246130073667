.editor-pick-tile{
    height: auto;
    width: 100%;
    margin-bottom: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.editor-pick-tile-overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 5px 5px 5px 5px;
    background-color:#000000a0;
    mix-blend-mode:luminosity;
}

.editor-pick-reason-area{
    position: absolute;
    height: auto;
    width: 96%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}


.ep-reason-heading{
    font-size: 1.7rem;
    font-weight: 700;
    color: #f8f8f8;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    text-align: center;
    text-wrap: wrap;
}

.editor-pick-text-container{
    height: auto;
    width: 65%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.ep-reason-text{
    font-size: 1rem;
    font-weight: 500;
    line-height: 24px;
    color: #f8f8f8;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    text-align: center;
    text-wrap: wrap;
}

.editor-pick-tile-bg{
    height: 350px;
    width: 100%;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.editor-pick-tile-bg img{
    height: 100%;
    width: 100%;
    border-radius: 5px 5px 5px 5px;
    aspect-ratio: 2/3;
    object-fit: cover;
}

.ep-tile-action-btns{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ep-tile-cta{
    height: auto;
    width: auto;
    padding: 1.5%;
    padding-left: 3%;
    padding-right: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    border-radius: 5px 5px 5px 5px;
    background-color: #FFE74C;
    font-size: 0.9rem;
    font-weight: 600;
    color: #080808;
}

.ep-tile-secondary-cta{
    height: auto;
    width: auto;
    padding: 1.5%;
    padding-left: 3%;
    padding-right: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    border-radius: 5px 5px 5px 5px;
    background-color: transparent;
    font-size: 0.9rem;
    font-weight: 600;
    color: #f8f8f8;
}
.ep-tile-secondary-cta:hover{
    cursor: pointer;
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
    .editor-pick-text-container{
        /* background-color: mediumpurple; */
        height: auto;
        width: 95%;
        /* margin-bottom: 20px; */
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .ep-reason-heading{
        font-size: 1.5rem;
    }

    .ep-reason-text{
        font-size: 0.95rem;
        font-weight: 500;
        line-height: 20px;
    }

    .editor-pick-tile-overlay{
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 5px 5px 5px 5px;
        background-color:#000000aa;
        mix-blend-mode:luminosity;
    }
}

/* For tablets */
@media only screen and (min-width: 600px) and (max-width: 1279px){
    .editor-pick-tile-bg{
        height: 275px;
    }

    .ep-reason-text{
        display: none;
    }
}