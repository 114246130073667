.review-carousel-tile{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #191917;
    border-radius: 5px 5px 5px 5px;
}

.review-game-cover-container{
    position: relative;
    height: 275px;
    width: 100%;
    margin-bottom: 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #080808;
}
.review-game-cover-container img{
    position: absolute;
    height: 100%;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    object-fit: cover;
    aspect-ratio: 2/3;
}
.review-game-cover-overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: linear-gradient(to top, #080808, #353535, #737373, #f8f8f8);
    opacity: 0.75;
    mix-blend-mode: multiply;
}
.review-carousel-game-controls{
    position: absolute;
    height: 100%;
    width: 95%;
    padding: 1%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.review-carousel-game-controls p{
    font-size: 1.25rem;
    font-weight: 700;
    color: #f8f8f8;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
}
.review-carousel-gamepage-nav-btn{
    text-decoration: none;
    height: auto;
    width: auto;
    margin-bottom: 10px;
    padding: 1%;
    padding-left: 2%;
    padding-right: 2%;
    border: none;
    outline: none;
    border-radius: 5px 5px 5px 5px;
    background-color: #FFE74C;
    color: #080808;
    font-size: 0.9rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.review-carousel-item-content{
    height: auto;
    width: 95%;
    padding: 1%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.review-carousel-item-user-info{
    height: auto;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.review-carousel-item-user-info-pfp{
    height: 35px;
    width: 35px;
    margin-right: 7px;
    border-radius: 50%;
    background-color: #FFE74C;
    color: #080808;
    font-size: 0.9rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}
.review-carousel-item-user-info-pfp img{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    aspect-ratio: 3/2;
}
.review-carousel-item-user-info-username{
    font-size: 0.95rem;
    font-weight: 600;
    color: #f8f8f8;
    margin: 0;
    margin-right: 10px;
    padding: 0;
}
.review-carousel-item-date{
    font-size: 0.7rem;
    font-weight: 500;
    color: #A6A6A6;
    margin: 0;
    padding: 0;
}

.review-carousel-item-text{
    height: auto;
    width: 100%;
    margin-bottom: 10px;
    overflow: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.review-carousel-item-text-para{
    text-align: left;
    font-size: 0.95rem;
    font-weight: 500;
    color: #f8f8f8;
    margin: 0;
    padding: 0;
}
.review-carousel-item-score{
    text-align: left;
    font-size: 1.5rem;
    font-weight: 500;
    color: #FFE74C;
    margin: 0;
    margin-bottom: 7px;
    padding: 0;
}
.review-carousel-item-action-buttons{
    height: auto;
    width: 98%;
    padding: 1%;
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
    .review-carousel-game-controls{
        position: absolute;
        height: 100%;
        width: 95%;
        padding: 1%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
    }
    .review-carousel-item-action-buttons{
        height: auto;
        width: 98%;
        padding: 1%;
        display: flex;
        justify-content: flex-start;
    }
}