.form-input-textarea{
    margin-bottom: 5px;
    width: 96%;
    height: auto;
    border-radius: 10px 10px 10px 10px;
    border: solid #f8f8f8;
    border-width: 1px;
    outline: none;
    resize: none;
    padding: 2%;
    color: #f8f8f8;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
    background-color: transparent;
}