.game-platform-cta{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 0.8rem;
    font-weight: 600;
    color: #f8f8f8;
    cursor: pointer;
    transition: 0.3s font-size ease-in-out;
}

.game-platform-cta:hover{
    font-size: 0.9rem;
    font-weight: 600;
    color: #FFE74C;
}
