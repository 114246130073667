.genre-about-component{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.genre-about-para-div{
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    font-size: 0.95rem;
    text-align: left;
    color: #f8f8f8;
    line-height: 28px;
}

.genre-about-read-more-cta{
    height: 35px;
    width: auto;
    padding-left: 3%;
    padding-right: 3%;
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 600;
    color: #FFE74C;
    cursor: pointer;
}
