.discussion-tile{
    margin-bottom: 15px;
    height: auto;
    width: 100%;
    border-radius: 10px 10px 10px 10px;
    background-color: #080808;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.discussion-tile-header-row{
    height: 40px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.discussion-tile-user{
    height: 100%;
    width: 75%;
    padding-left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.discussion-tile-user-pfp{
    height: 40px;
    width: 40px;
    margin-right: 10px;
    border-radius: 50% 50% 50% 50%;
    background-color: #FFE74C;
    display: flex;
    justify-content: center;
    align-items: center;
}
.discussion-tile-user-pfp p{
    font-size: 0.9rem;
    font-weight: 500;
    color: #080808;
}
.discussion-tile-user-pfp img{
    height: 100%;
    width: 100%;
    border-radius: 50% 50% 50% 50%;
    object-fit: cover;
}

.discussion-tile-user-detail{
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.discussion-tile-user-detail p{
    margin: 0;
    padding: 0;
    font-size: 1rem;
    color: #f8f8f8;
    font-weight: 600;
}

.discussion-tile-spoiler-alert{
    height: 100%;
    width: 20%;
    padding-left: 2%;
    padding-right: 2%;
    background-color: rgb(201, 23, 23);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    color: #f8f8f8;
    font-weight: 700;
}

.discussion-tile-info-row{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.info-row-approval-section{
    height: 75%;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.info-row-approval-section p{
    font-size: 1.2rem;
    font-weight: 700;
    color: #f8f8f8;
}

.info-row-title-section{
    height: auto;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 1%;
    font-size: 1.5rem;
    color: #f8f8f8;
    font-weight: 600;
    text-align: left;
    cursor: pointer;
}
.info-row-title-section p{
    margin-bottom: 7px;
    font-size: 0.9rem;
    color: #FFE74C;
    font-weight: 700;
    text-align: left;
}

.info-row-tagged-games-section{
    height: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.info-row-tagged-game-tile{
    height: auto;
    width: auto;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-right: 3%;
    padding-left: 3%;
    margin: 5px;
    border-radius: 25px 25px 25px 25px;
    background-color: #FFE74C;
    color: #080808;
    font-size: 0.75rem;
    font-weight: 700;
    cursor: pointer;
}

.discussion-tile-action-row{
    height: 50px;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.action-row-cta{
    height: 35px;
    width: 100px;
    margin-right: 15px;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    color: #FFE74C;
    font-weight: 600;
    cursor: pointer;
    transition: 0.5s;
    -webkit-transition: width 0.5s;
    transition: 0.5s;
}
.action-row-cta:hover{
    color: #f6e573;
    font-weight: 800;
    font-size: 1rem;
}

/* For mobile devices */
@media only screen and (max-width: 600px) {
    .discussion-tile{
        margin-bottom: 7px;
        height: auto;
        width: 98%;
        border-radius: 10px 10px 10px 10px;
        background-color: #080808;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}