.console-page-container{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.all-console-tiles-container{
    height: auto;
    width: 100%;
    overflow-x: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    scroll-behavior: smooth;
    /* For firefox browser */
    scrollbar-color: #FFE74C transparent; /* Thumb color and track color */
    scrollbar-width: thin;
}

.all-console-tiles-container::-webkit-scrollbar{
    height: 20px;
}

.all-console-tiles-container::-webkit-scrollbar-track{
    background: transparent;
}

.all-console-tiles-container::-webkit-scrollbar-thumb{
    color: #FFE74C;
    border-radius: 10px 10px 10px 10px;
}

.all-console-tile{
    height: 35px;
    white-space: nowrap;
    width: auto;
    padding-left: 1%;
    padding-right: 1%;
    margin: 5px;
    border: none;
    outline: none;
    background-color: #191917;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
    color: #f8f8f8;
    cursor: pointer;
}
.all-console-tile.selected{
    background-color: #FFE74C;
    color: #080808;
}
.main-console-page-area{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.selected-console-data-area{
    flex: 3;
    height: 100%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.console-ad-slot-1{
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.console-ad-slot-2{
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
    .console-ad-slot-2{
        display: none;
    }   

    .main-console-page-area{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
}