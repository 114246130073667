.console-full-data-container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.console-space-div{
    height: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.console-heading-and-cta-area{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.console-cta-container{
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.console-add-cta{
    height: 30px;
    text-wrap: nowrap;
    width: auto;
    padding: 1%;
    padding-left: 6%;
    padding-right: 6%;
    border: none;
    outline: none;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFE74C;
    font-size: 0.8rem;
    color: #080808;
    font-weight: 600;
    box-shadow: 0px 0px 7px rgba(255, 255, 255, 0.5);
    cursor: pointer;
}
.console-add-cta:hover{
    box-shadow: none;
}
.console-heading{
    text-align: left;
    font-size: 2rem;
    font-weight: 700;
    color: #f8f8f8;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
}
.console-subheading{
    text-align: left;
    font-size: 1.25rem;
    font-weight: 600;
    color: #b3b3b3;
    margin: 0;
    padding: 0;
}
.console-subheading-1{
    text-align: left;
    font-size: 1.5rem;
    font-weight: 600;
    color: #f8f8f8;
    margin: 0;
    padding: 0;
}
.console-normal-text{
    text-align: left;
    font-size: 0.975rem;
    font-weight: 500;
    color: #f8f8f8;
    line-height: 28px;
    text-wrap: wrap;
}
.console-about-picture{
    float: left;
    height: 275px;
    width: 200px;
    margin-right: 10px;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s height ease-in-out, 0.3s width ease-in-out;
}
/* .console-about-picture:hover{
    position: absolute;
    height: 350px;
    width: 275px;
    z-index: 9999;
} */
.console-about-picture img{
    height: 100%;
    width: 100%;
    border-radius: 5px 5px 5px 5px;
    object-fit: contain;
    aspect-ratio: 2/3;
}
.console-about-div{
    max-height: 0;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    transition: max-height 0.5s ease-in-out;
}
.collapse-div-action{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.console-about-read-more-cta{
    height: 35px;
    width: auto;
    padding-left: 3%;
    padding-right: 3%;
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 600;
    color: #FFE74C;
}

.console-games-list-container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
}

.console-game-tile{
    height: 270px;
    width: 200px;
    margin: 7px;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: transform 0.3s ease-in-out, z-index 0.3s, width 0.3s ease-in-out, height 0.3s ease-in-out;
}
/* .console-game-tile:hover{
    cursor: pointer;
    height: 350px;
    width: 300px;
    z-index: 1;
    transform: scale(1.2);
} */

.console-game-tile img{
    height: 100%;
    width: 100%;
    aspect-ratio: 2/3;
    object-fit: cover;
    border-radius: 5px 5px 5px 5px;
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
    .console-heading{
        font-size: 1.25rem;
    }
    .console-subheading{
        font-size: 0.95rem;
    }
    .console-subheading-1{
        font-size: 1.2rem;
    }
    .console-normal-text{
        font-size: 0.85rem;
    }
    .console-about-read-more-cta{
        font-size: 0.75rem;
    }

    .console-game-tile{
        height: 170px;
        width: 120px;
        margin: 2.5px;
    }
    .console-game-tile:hover{
        height: 170px;
        width: 125px;
    }
}