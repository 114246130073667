.user-signup-container{
    /* background-color: yellowgreen; */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.user-signup-container h4{
    color: #f8f8f8;
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
}

.user-signup-input-form{
    height: auto;
    width: 50%;
    display: flex;
    gap: 7.5px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.signup-password-area{
    height: 30px;
    width: 100%;
    /* padding: 2%; */
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}
.signup-password-input{
    height: 98%;
    width: 95%;
    /* padding: 2%; */
    /* margin-bottom: 40px; */
    margin-top: 30px;
    background-color: transparent;
    border: solid #EEE5E9;
    border-radius: 10px 10px 10px 10px;
    outline: none;
    color: #f8f8f8;
    font-size: medium;
    font-weight: 500;
    position: absolute;
}
.visibility-container{
    height: 85%;
    width: 7%;
    padding: 1%;
    margin-top: 10px;
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}
.cta{
    height: 50px;
    width: 100%;
    padding: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    border: none;
    background-color: #FFE74C;
    color: #191917;
    font-size: medium;
    font-weight: bolder;
    margin-bottom: 30px;
}
.cta:hover{
    background-color: #feec79;
    cursor: pointer;
}

.signup-other-option-prompt{
    height: 24px;
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.signup-other-option-prompt p{
    margin: 0;
    padding: 0;
}
.signup-other-option-prompt h4{
    margin: 0;
    padding: 0;
    margin-left: 5px;
    color: #FFE74C;
    cursor: pointer;
}

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .user-signup-container{
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .user-signup-input-form{
        height: auto;
        width: 98%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}
/* FOR MOBILE DEVICES ONLY */