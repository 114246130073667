.main-game-review-page{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 2%;
}
.main-game-reviews-header{
    height: 40vh;
    width: 100%;
    box-sizing: border-box;
    padding: 0.5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-game-review-about-container{
    height: 100%;
    width: 50%;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
}

.main-game-profile-picture{
    height: 100%;
    width: 275px;
    border-radius: 5px 5px 5px 5px;
}
.main-game-profile-picture img{
    height: 100%;
    width: 100%;
    border-radius: 5px 5px 5px 5px;
    aspect-ratio: 2/3;
    object-fit: cover;
}

.main-game-about{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}

.main-game-review-heading{
    text-align: left;
    color: #FFE74C;
    margin: 0;
    padding: 0;
}
.main-game-review-subheading{
    color: #f8f8f8;
    margin: 0;
    padding: 0;
    text-align: left;
    word-wrap: break-word;
}
.main-game-review-subheading-1{
    color: #FFE74C;
    margin: 0;
    padding: 0;
    text-align: left;
    word-wrap: break-word;
}

.main-game-review-main{
    box-sizing: border-box;
    margin-top: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
}

.main-game-rev-ad-slot-section-1{
    flex: 1;
    height: 100%;
    background-color: mediumpurple;
}
.main-game-rev-ad-slot-section-2{
    flex: 1;
    height: 100%;
    background-color: mediumpurple;
}
.main-game-review-section{
    flex: 3;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.main-game-review-btn{
    box-sizing: border-box;
    width: auto;
    height: auto;
    padding: 1.5%;
    padding-left: 3%;
    padding-right: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #FFE74C;
    font-size: 0.9rem;
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
    .main-game-reviews-header{
        height: auto;
        width: 100%;
    }
    .main-game-review-about-container{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
    }

    .main-game-profile-picture{
        height: 40vh;
        width: 100%;
        border-radius: 5px 5px 5px 5px;
    }

    .main-game-review-main{
        box-sizing: border-box;
        margin-top: 20px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 7px;
    }

    .main-game-rev-ad-slot-section-1{
        display: none;
    }
    .main-game-rev-ad-slot-section-2{
        display: none;
    }
}

/* For tablets */
@media only screen and (min-width: 600px) and (max-width: 1279px){
    .main-game-reviews-header{
        height: auto;
        width: 100%;
    }
    .main-game-review-about-container{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
    }

    .main-game-profile-picture{
        height: 25vh;
        width: 100%;
        border-radius: 5px 5px 5px 5px;
    }

    .main-game-rev-ad-slot-section-1{
        display: none;
    }
}