.main-game-gallery-carousel {
    width: 100%;
    max-width: 800px; 
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.main-game-gallery-carousel-slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}

.main-game-gallery-carousel-slide img {
    height: 100%;
    width: 100%;
    aspect-ratio: 2/3;
    object-fit: contain;
}


/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
    .main-game-gallery-carousel {
        width: 100%;
        max-width: 800px; 
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
}
