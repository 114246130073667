/* Styling for forum home page to be done here. */
.forum-homepage-container{
    height: auto;
    width: 100%;
    background-color: #191917;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.all-forums-heading{
    height: 5%;
    width: 98%;
    padding: 1%;
    padding-left: 3%;
    /* background-color: yellowgreen; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.all-forums-heading h4{
    margin: 0;
    padding: 0;
    color: #f8f8f8;
}
.forum-action-container{
    height: 100%;
    width: 50%;
    padding: 1%;
    /* background-color: violet; */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.new-discussion-cta{
    height: 45px;
    width: 25%;
    padding: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    border: none;
    background-color: #FFE74C;
    color: #191917;
    font-size: 14px;
    font-weight: 600;
}

.active-forum-component{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* For mobile devices */
@media only screen and (max-width: 600px) {
    .all-forums-heading{
        height: 5%;
        width: 98%;
        padding: 1%;
        padding-left: 3%;
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .new-discussion-cta{
        height: 45px;
        width: 100%;
        padding: 1%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px 10px 10px 10px;
        border: none;
        background-color: #FFE74C;
        color: #191917;
        font-size: 14px;
        font-weight: 600;
    }
}