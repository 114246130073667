.discussion-comment-area{
    height: auto;
    width: 98%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    /* background-color: #191917; */
    background-color: #080808;
}

.normal-text-input-area{
    height: auto;
    width: 100%;
    margin-bottom: 7px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.forum-cta-btn{
    margin: 5px;
    height: 20px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px 5px 5px 5px;
    background-color: #FFE74C;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 2%;
    padding-right: 2%;
    color: #080808;
    font-size: 0.85rem;
    font-weight: 600;
    cursor: pointer;
}

.comment-posting-error-alert{
    color: #f8f8f8;
    width: 100%;
    text-align: center;
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
    .discussion-comment-area{
        height: auto;
        width: 98%;
        padding: 2%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 10px 10px 10px 10px;
        background-color: #191917;
    }
}