.trending-review-loading-tile{
    height: 275px;
    width: 98%;
    padding: 1%;
    background-color: #191917;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.trending-rev-game-cover-loading{
    height: 150px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #292927;
}
.review-texts-loading{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}
.review-user-pfp-loading{
    height: auto;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.review-user-pic-loading{
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: #292927;
    margin-right: 7px;
}
.review-user-usernmame-loading{
    height: 2px;
    width: 25%;
    margin-right: 7px;
    border-radius: 25px 25px 25px 25px;
    padding: 1%;
    background-color: #292927;
}
.review-date-loading{
    height: 0.5px;
    width: 15%;
    padding: 0.5%;
    border-radius: 25px 25px 25px 25px;
    background-color: #292927;
}
.review-main-text-loading{
    height: 25px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px 5px 5px 5px;
    background-color: #292927;
}
.review-action-btns-section-loading{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.review-action-btn-loading{
    height: 0.9rem;
    width: 100px;
    padding: 1%;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 5px 5px 5px 5px;
    background-color: #292927;
}

@media only screen and (max-width: 600px) {
    .review-action-btns-section-loading{
        justify-content: flex-start;
    }
}