.all-forums-container{
    min-height: auto;
    max-height: 9999999999vh;
    width: 100%;
    background-color: #191917;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
}

.discussion-preview-tile-container{
    height: auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.fetching-prompt{
    height: auto;
    width: 50%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFE74C;
}

.load-more-discussions-cta{
    height: 35px;
    width: 120px;
    margin: 20px;
    /* background-color: #FFE74C; */
    border-radius: 10px 10px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    color: #FFE74C;
    font-weight: 600;
    cursor: pointer;
}
.load-more-discussions-cta:hover{
    background-color: #FFE74C;
    color: #080808;
}

/* For mobile devices */
@media only screen and (max-width: 600px) {
    .all-forums-container{
        min-height: 100vh;
        max-height: 9999999999vh;
        width: 100%;
        /* background-color: blue; */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow-x: hidden;
    }
    .discussion-preview-tile-container{
        width: 100%;
    }
}