.homepage-container{
    min-height: 85vh;
    width: 100%;
    background-color: #080808;
    display: flex;
    align-items: flex-start;
}

.auth-modal-dialog{
    padding: 0;
    width: 100%;
    background-color: #08080847;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homepage-ad-components-1{
    flex: 1;
    min-height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.homepage-ad-components-2{
    flex: 1;
    min-height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homepage-banner-ad-component{
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homepage-contents{
    flex: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.banner-ad-container{
    display: none;
}


/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
    .homepage-container{
        height: 100%;
        width: 100%;
        background-color: #080808;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .homepage-ad-components-1{
        display: none;
    }
    .homepage-ad-components-2{
        display: none;
    }
    .homepage-contents{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .banner-ad-container{
        height: auto;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: contain;
    }
}

/* For tablets */
@media only screen and (min-width: 600px) and (max-width: 1279px){
    .homepage-contents{
        width: 100%;
    }
    .homepage-ad-components-1{
        display: none;
    }

    .banner-ad-container{
        height: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}