.user-review-write-text-input{
    background-color: #080808;
    color: #f8f8f8;
    width: 100%;
    border: none;
    padding: 1%;
    outline: none;
    resize: none;
    overflow: hidden;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
}

.user-review-write-text-input::placeholder{
    font-size: 1.2rem;
    font-weight: 500;
    font-style: italic;
}

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .user-review-write-text-input{
        font-size: 1rem;
        font-weight: 500;
        font-family: Arial, Helvetica, sans-serif;
    }
    .user-review-write-text-input::placeholder{
        font-size: 1rem;
        font-weight: 500;
        font-style: italic;
    }
}