/* styling for sharing dialog box. */
/* SHARING ICON */
.twitter-icon{
    height: 65px;
    width: 65px;
    padding: 2%;
    background-color: #080808;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.twitter-icon img{
    height: 65%;
    width: 65%;
    object-fit: cover;
    object-position: center;
}
/* SHARING ICON */

.sharing-dialog-container{
    background-color: #191917;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.sharing-header-row{
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.sharing-header-row p{
    font-size: 0.9rem;
    font-weight: 600;
    color: #f8f8f8;
    margin-left: 10px;
}
.sharing-platforms-row{
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow-x: auto;
}

.separator{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 3%;
    border-top: solid #f8f8f835;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #f8f8f8;
}

.copy-to-clipboard-section{
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.copy-url-area{
    height: 35px;
    padding: 1%;
    background-color: #080808;
    border-radius: 5px 5px 5px 5px;
    width: 80%;
    margin-right: 7px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 500;
    color: #f8f8f8;
}

.copy-url-cta{
    height: 35px;
    width: 100px;
    margin-right: 15px;
    background-color: #FFE74C;
    border: none;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    color: #080808;
    /* color: #FFE74C; */
    font-weight: 500;
    cursor: pointer;
    /* transition: 0.5s;
    -webkit-transition: width 0.5s;
    transition: 0.5s; */
}

/* For mobile devices */
@media only screen and (max-width: 600px) {
    .sharing-dialog-container{
        background-color: #191917;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .sharing-platforms-row{
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow-x: auto;
    }
    .sharing-platforms-row::-webkit-scrollbar{
        display: none;
    }

    .copy-url-area{
        height: 35px;
        /* padding: 1%; */
        background-color: #080808;
        border-radius: 5px 5px 5px 5px;
        width: 85%;
        margin-right: 7px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 0.9rem;
        font-weight: 500;
        color: #f8f8f8;
        overflow-x: auto;
    }
}