.user-settings-container{
    height: auto;
    width: 98%;
    padding: 1%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
}
.save-changes-cta-container{
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.user-profile-picture-settings-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.user-profile-picture-settings-container h4{
    color: #f8f8f8;
    margin: 0;
    padding-bottom: 1%;
}
.profile-picture-box{
    height: 250px;
    width: 250px;
    margin-bottom: 20px;
    /* background-color: black; */
    position: relative;
    cursor: pointer;
}
.profile-picture-container{
    height: 250px;
    width: 250px;
    border-radius: 125px 125px 125px 125px;
    margin-bottom: 10px;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}
.profile-picture-container img{
    height: 100%;
    width: 100%;
    border-radius: 125px 125px 125px 125px;
    object-fit: cover;
    object-position: center;
}
.edit-picture-icon-container{
    bottom: 0;
    right: 20px;
    height: 50px;
    width: 50px;
    border-radius: 50% 50% 50% 50%;
    background-color: #232322;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}
.edit-picture-icon-container label{
    height: 50px;
    width: 50px;
    border-radius: 50% 50% 50% 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.edit-picture-icon-container input{
    display: none;
}

.user-text-credential-container{
    height: 70px;
    width: 45%;
    margin-top: 25px;
    margin-bottom: 25px;
    /* background-color: brown; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.user-text-credential-container h4{
    color: #f8f8f8;
    padding: 0;
    margin: 0;
}
.username-input-container{
    height: 70px;
    width: 100%;
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.username-input-text{
    height: 50px;
    min-width: 100%;
    padding: 2%;
    margin-right: 7px;
    background-color: #242422;
    border: none;
    outline: none;
    border-radius: 5px 5px 5px 5px;
    font-size: 24px;
    color: #f8f8f8;
}

.no-cover-image-prompt{
    height: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #f8f8f8;
    font-size: 1rem;
    font-weight: 500;
}

.change-cover-image-container{
    height: 500px;
    width: 100%;
    margin-top: 30px;
    /* background-color: violet; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.change-cover-image-container-heading{
    height: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.change-cover-image-container-heading h4{
    color: #f8f8f8;
    padding: 1%;
    margin: 0;
}
.change-cover-image-container-heading h5{
    color: #f8f8f8;
    margin: 0;
}
.change-cover-image-container-heading input{
    display: none;
}
.change-cover-image-edit-container{
    width: 7%;
    font-size: 14px;
    font-weight: 900;
    color: #f8f8f8;
    padding: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
}

.cover-image-preview-container{
    height: 450px;
    width: 85%;
    margin-top: 1%;
    background-color: #191917;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.cover-image-preview-container img{
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 10px 10px;
    object-fit: cover;
    position: absolute;
}

.cover-image-overlay{
    height: 100%;
    width: 100%;
    background-color: #191917;
    opacity: 0.7;
    border-radius: 10px 10px 10px 10px;
    position: absolute;
}

.preview-components-container{
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
}

.preview-component-nav-container{
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.preview-component-profile{
    height: 100px;
    width: 100px;
    border-radius: 125px 125px 125px 125px;
    margin-bottom: 10px;
    background-color: #000000af;
}
.preview-component-nav-options{
    height: auto;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.preview-nav-option{
    height: 25px;
    width: 100%;
    border-radius: 10px 10px 10px 10px;
    background-color: #FFE74C;
    margin: 5px;
}
.preview-component-items-container{
    height: 75%;
    width: 100%;
    margin-left: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}
.preview-item-tile{
    height: 150px;
    width: 100px;
    background-color: #000000a1;
    border-radius: 10px 10px 10px 10px;
    margin: 5px;
}

.check-settings-username-availibility-container{
    height: 10px;
    width: 102%;
    padding-right: 1%;
    margin-bottom: 20px;
    margin-top: 7px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.username-settings-availibility-button{
    font-size: 0.9rem;
    font-weight: 700;
    color: #FFE74C;
    cursor: pointer;
}

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {

    .user-profile-picture-settings-container{
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        /* background-color: aquamarine; */
    }
    .profile-picture-box{
        height: 120px;
        width: 120px;
        margin-bottom: 20px;
        position: relative;
        cursor: pointer;
    }
    .profile-picture-container{
        height: 120px;
        width: 120px;
        border-radius: 50% 50% 50% 50%;
        margin-bottom: 10px;
        background-color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
    }
    .profile-picture-container img{
        height: 100%;
        width: 100%;
        border-radius: 50% 50% 50% 50%;
        object-fit: cover;
        object-position: center;
    }
    .edit-picture-icon-container{
        bottom: 0;
        right: 0;
        height: 35px;
        width: 35px;
        border-radius: 50% 50% 50% 50%;
        background-color: #232322;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .edit-picture-icon-container label{
        height: 35px;
        width: 35px;
        border-radius: 50% 50% 50% 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .edit-picture-icon-container input{
        display: none;
    }

    .user-text-credential-container{
        height: 70px;
        width: 90%;
        margin-top: 25px;
        margin-bottom: 25px;
        /* background-color: brown; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .user-text-credential-container h4{
        color: #f8f8f8;
        padding: 0;
        margin: 0;
    }
    .username-input-container{
        height: 70px;
        width: 100%;
        margin-top: 1%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .username-input-text{
        height: 50px;
        min-width: 100%;
        padding: 2%;
        margin-right: 7px;
        background-color: #242422;
        border: none;
        outline: none;
        border-radius: 5px 5px 5px 5px;
        font-size: 24px;
        color: #f8f8f8;
    }
    .change-cover-image-container{
        height: 500px;
        width: 95%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .change-cover-image-container-heading{
        height: 30px;
        width: 95%;
        padding: 2%;
        /* background-color: turquoise; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .cover-image-preview-container{
        height: 450px;
        width: 100%;
        /* margin-top: 1%; */
        background-color: #191917;
        border-radius: 5px 5px 5px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .cover-image-preview-container{
        height: 450px;
        width: 100%;
        background-color: #191917;
        border-radius: 5px 5px 5px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .preview-components-container{
        height: 100%;
        width: 100%;
        border-radius: 10px 10px 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        position: absolute;
    }

    .preview-component-nav-container{
        height: 100%;
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    .preview-component-profile{
        margin-top: 10%;
        height: 100px;
        width: 100px;
        border-radius: 50% 50% 50% 50%;
        margin-bottom: 10px;
        background-color: #000000af;
    }

    .preview-component-nav-options{
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .preview-item-tile{
        height: 100px;
        width: 75px;
        background-color: #000000a1;
        border-radius: 10px 10px 10px 10px;
        margin: 5px;
    }
}
/* FOR MOBILE DEVICES ONLY */