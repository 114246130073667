/* styling of user comments section. */
.user-created-comments{
    min-height: 95%;
    width: 98%;
    padding: 1%;
    /* overflow-y: auto; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.user-comment-box-container{
    height: auto;
    width: 98%;
    margin: 5px;
    background-color: #191917;
    border-radius: 10px 10px 10px 10px;
}

.user-comments-section-action-bar{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.user-comments-cta-btn{
    height: 30px;
    width: 178px;
    background-color: transparent;
    color: #f8f8f8;
    font-size: 0.95rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    border: none;
}
.user-comments-cta-btn:hover{
    border-radius: 25px 25px 25px 25px;
    background-color: #ffe74cb3;
    color: #080808;
}

.no-comment-prompt{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-comment-prompt p{
    padding: 2%;
    background-color: #FFE74C;
    font-size: 16px;
    font-weight: 600;
    color: #080808;
}
.user-comment-box-extra{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5%;
}
.user-comment-box-txt{
    font-size: 0.75rem;
    font-weight: 600;
    color: #f8f8f8;
    text-align: left;
}


@media only screen and (max-width: 600px) {
    .user-created-comments{
        min-height: 95%;
        width: 100%;
        /* background-color: blue; */
        /* overflow-y: auto; */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .user-comment-box-container{
        height: auto;
        width: 100%;
        padding: 1%;
        margin: 5px;
        background-color: #191917;
        border-radius: 10px 10px 10px 10px;
        overflow-x: visible;
    }
    .user-comment-box-extra{
        height: auto;
        width: 98%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1.5%;
        flex-wrap: wrap;
        text-align: left;
    }

}