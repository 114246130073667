.user-review-write-container{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    background-color: #080808;
}

.user-review-write-text-box{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.user-review-write-pfp{
    height: 35px;
    width: 35px;
    margin-right: 7px;
    border-radius: 50% 50% 50% 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFE74C;
}
.user-review-write-pfp p{
    color: #080808;
    font-size: 0.95rem;
    font-weight: 500;
}
.user-review-write-pfp img{
    height: 100%;
    width: 100%;
    border-radius: 50% 50% 50% 50%;
    object-fit: cover;
    aspect-ratio: 3/2;
}

.user-review-write-error-box{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-review-write-error-box p{
    font-size: 0.9rem;
    font-weight: 700;
    color: #f8f8f8;
}

/* For when the review is already written by the user */
.user-review-write-already-present{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.user-review-present-1{
    font-size: 0.95rem;
    font-weight: 800;
    color: #f8f8f8;
    margin-bottom: 7px;
}
.user-review-present-2{
    font-size: 0.9rem;
    font-weight: 500;
    color: #f8f8f8;
    margin-bottom: 10px;
}
/* For when the review is already written by the user */

.user-review-write-post-btn-div{
    margin-top: 10px;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.user-review-write-post-btn{
    height: auto;
    width: auto;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    padding-left: 3%;
    padding-right: 3%;
    border: none;
    border-radius: 5px 5px 5px 5px;
    outline: none;
    background-color: #FFE74C;
    color: #080808;
    font-size: 0.95rem;
    font-weight: 600;
}
.user-review-write-post-btn:hover{
    cursor: pointer;
}

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {

    .user-review-write-text-box{
        margin: 10px;
    }

    .user-review-write-post-btn-div{
        height: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .user-review-write-post-btn{
        height: auto;
        width: auto;
        padding-top: 2.5%;
        padding-bottom: 2.5%;
        padding-left: 5%;
        padding-right: 5%;
    }

}