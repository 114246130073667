.game-heading{
    text-align: left;
    font-size: 2rem;
    font-weight: 700;
    color: #f8f8f8;
    margin-bottom: 7px;
}
.game-sub-heading{
    text-align: left;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 7px;
    color: #f8f8f8;
}
.game-sub-sub-heading{
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 10px;
    color: #f8f8f8;
}

.game-profile-sub-heading{
    margin-top: 10px;
    text-align: left;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 5px;
    color: #FFE74C;
}

.game-platforms-container{
    margin-bottom: 10px;
    height: auto;
    width: 100%;
    display: flex;
    gap: 2px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}
.game-platform{
    display: flex;
    justify-content: center;
    align-items: center;
}
.game-platform-comma{
    font-size: 0.9rem;
    font-weight: 500;
    color: #f8f8f8;
    margin: 0;
    padding: 0;
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {

    .game-heading{
        text-align: center;
        font-size: 2rem;
        font-weight: 700;
        color: #f8f8f8;
        margin-bottom: 7px;
    }

    .game-platforms-container{
        display: flex;
        justify-content: center;
        align-items: center;
    }

}