.comment-user-profile{
    height: auto;
    width: 100%;
    margin-bottom: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.comment-user-info{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.comment-user-pfp{
    height: 30px;
    width: 30px;
    margin-right: 7px;
    border-radius: 50% 50% 50% 50%;
    background-color: #080808;
    display: flex;
    justify-content: center;
    align-items: center;
}
.comment-user-pfp img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50% 50% 50% 50%;
}
.comment-user-pfp p{
    margin: 0;
    padding: 0;
    text-align: left;
}

.comment-user-name{
    font-size: 0.75rem;
    font-weight: 600;
    color: #f8f8f8;
    text-align: left;
}