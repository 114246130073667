.liking-screen-overlay{
    position: absolute;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    /* opacity: 0.5; */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999999999999;
    opacity: 0;
    animation: smooth-appear 0.5s ease-in-out forwards;
}

@keyframes smooth-appear {
    to{
        opacity: 1;
    }
}


.liking-collection-prompt-area{
    height: 25%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.liking-collection-text{
    font-size: 4rem;
    font-weight: 500;
    color: #FFE74C;
    font-family: 'Paciencia';
}

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .liking-screen-overlay{
        position: absolute;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* opacity: 0.5; */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        z-index: 999999999999999;
        opacity: 0;
        animation: smooth-appear 0.5s ease-in-out forwards;
    }
    .liking-collection-prompt-area{
        height: 25%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(5px);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    
    .liking-collection-text{
        font-size: 2rem;
        font-weight: 500;
        color: #FFE74C;
        font-family: 'Paciencia';
    }
}