.user-review-write-scoring-box{
    height: auto;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}

.user-review-write-scoring-heading{
    font-size: 1rem;
    font-weight: 600;
    color: #FFE74C;
    margin-bottom: 10px;
}

.user-review-scoring-row{
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.user-rev-score-box{
    height: 50px;
    width: 50px;
    border-radius: 5px 5px 5px 5px;
    border: none;
    outline: none;
    background-color: #191917;
    color: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.5s;
    -webkit-transition: height 0.5s;
}

.user-rev-score-box:hover{
    background-color: #FFE74C;
    height: 75px;
    width: 75px;
    font-size: 1rem;
    font-weight: 600;
    color: #080808;
}

.user-rev-score-box.active{
    background-color: #FFE74C;
    height: 75px;
    width: 75px;
    font-size: 1rem;
    font-weight: 600;
    color: #080808;
}



/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .user-rev-score-box{
        height: 35px;
        width: 35px;
    }

    .user-rev-score-box.active{
        height: 40px;
        width: 40px;
    }
}