.search-game-tile-box{
    height: 45vh;
    width: 50vh;
    margin-right: 2px;
    margin-bottom: 5px;
    color: #191917;
    position: relative;
    transition: height 0.5s;
    -webkit-transition: height 0.5s;
    text-align: center;
    overflow: hidden;
    transition: 1.5s;
}

.search-game-tile-box:hover{
    height: 50vh;
    width: 52vh;
    background-color: transparent;
}

.search-game-tile-box img{
    height: 100%;
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: contain;
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
    
    .search-game-tile-box{
        height: 100px;
        width: 120px;
        margin-right: 2px;
        margin-bottom: 2px;
        color: #191917;
        transition: height 0.5s;
        -webkit-transition: height 0.5s;
        text-align: center;
        overflow: hidden;
        transition: 1.5s;
    }
    .search-game-tile-box:hover{
        height: 120px;
        width: 150px;
        background-color: transparent;
    }
    .search-game-tile-box img{
        max-height: 75px;
        max-width: 100%;
        object-fit: cover;
    }
    .search-game-tile-box p{
        font-size: 0.7rem;
        color:#f8f8f8;
    }

}