.console-menu-loading{
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.console-menu-data{
    height: auto;
    width: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
}