.search-user-tile{
    height: 90px;
    width: 95px;
    background-color: #080808;
    border-radius: 10px 10px 10px 10px;
    padding: 1%;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;

    transition: 0.5s;
    -webkit-transition: height 0.5s;
    transition: 0.5s;
}
.search-user-tile:hover{
    height: 100px;
    width: 97px;
}
.search-tile-dp{
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #FFE74C;
    margin-bottom: 7px;
}
.search-tile-dp img{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
}
.search-tile-no-dp{
    font-size: 0.9rem;
    font-weight: 700;
    color: #080808;
}
.search-tile-username{
    font-size: 0.65rem;
    font-weight: 700;
    color: #f8f8f8;
    text-align: left;
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
    .search-user-tile{
        height: auto;
        width: 93%;
        background-color: #080808;
        border-radius: 10px 10px 10px 10px;
        padding: 2%;
        margin-bottom: 5px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
    }

    .search-tile-dp{
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #FFE74C;
        margin-bottom: 7px;
        margin-right: 7px;
    }

    .search-tile-username{
        font-size: 0.8rem;
        font-weight: 700;
        color: #f8f8f8;
        text-align: left;
    }
}