.all-consoles-container{
    min-height: 100vh;
    width: 98%;
    padding: 1%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.add-consoles-area{
    height: 7%;
    width: auto;
    margin: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.adding-prompt{
    height: 35px;
    width: 150px;
    background-color: #FFE74C;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    border-radius: 5px 5px 5px 5px;
    font-size: 0.9rem;
    font-weight: 700;
    color: #080808;
}
.adding-prompt:hover{
    background-color: #FFE74C;
    color: #191917;
    cursor: pointer;
}

.selected-consoles-area{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.no-consoles-area{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.no-consoles-area p{
    padding: 2%;
    background-color: #FFE74C;
    color: #080808;
    font-weight: 600;
}

.console-detail-container{
    height: 275px;
    width: 250px;
    margin: 5px;
    border-radius: 10px 10px 10px 10px;
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.75);
    position: relative;
    transition: 0.5s;
    -webkit-transition: height 0.2s;
    transition: 0.5s;
}
.console-detail-container:hover{
    height: 300px;
    width: 300px;
    position: sticky;
}
.console-detail-container img{
    height: 90%;
    width: 100%;
    margin-bottom: 0;
    border-radius: 10px 10px 10px 10px;
    object-fit: contain;
}
.console-detail-container p{
    font-size: 0.9rem;
    font-weight: 700;
    color: #f8f8f8;
    margin-top: 2px;
    margin-bottom: 5px;
}


/* Chose console box dialog */
.chose-console-box-container{
    background-color: #191917;
}
.chose-console-box-container h4{
    margin: 0;
    padding: 0;
    color: #f8f8f8;
}

.console-tile{
    height: 100px;
    width: 94%;
    padding: 2%;
    background-color: #191917;
    border-radius: 10px 10px 10px 10px;
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.console-tile:hover{
    cursor: pointer;
    border: solid #FFE74C;
    border-width: 2px;
}
.console-tile-info{
    height: 100px;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.console-img-container{
    height: 100px;
    width: 75px;
    border-radius: 10px 10px 10px 10px;
    margin-right: 15px;
}
.console-img-container img{
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 10px 10px;
    object-fit: contain;
    object-position: center;
}

.console-profile-container{
    height: 100px;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}
.console-tile-actions{
    align-self: flex-end;
    width: 25%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.selected-console-container{
    background-color: #191917;
}
.selected-console-heading{
    width: 93%;
    padding: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.selected-console-heading h4{
    color: #f8f8f8;
}
.selected-console-name-part{
    width: 30%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.main-console-content{
    margin: 10px;
    width: 93%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.console-profile-company{
    font-size: 0.95rem;
    font-weight: 500;
    color: #b3b3b3;
}
.main-console-content p{
    font-size: 14px;
    font-weight: 500;
    line-height: 22.28px;
}

.console-suggestions-section{
    height: auto;
    width: 93%;
    padding: 1%;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
}
.console-suggestions-section::-webkit-scrollbar{
    display: none;
}
.suggested-game-tile{
    height: 150px;
    width: 100px;
    margin: 5px;
    border-radius: 10px 10px 10px 10px;
    color: #191917;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: 0.3s;
    -webkit-transition: height 0.3s;
    transition:0.3s;
}
.suggested-game-tile p{
    font-size: small;
    font-weight: 500;
}
.suggested-game-tile img{
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 10px 10px;
    object-fit: cover;
    object-position: center;
}
.suggested-game-tile:hover{
    height: 200px;
    width: 150px;
    position: sticky;
    cursor: pointer;
}
.user-console-primary-button{
    height: auto;
    width: auto;
    padding: 6%;
    padding-left: 12%;
    padding-right: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFE74C;
    border: none;
    outline: none;
    border-radius: 5px 5px 5px 5px;
    font-size: 0.9rem;
    font-weight: 600;
    color: #080808;
}
/* Chose console box dialog */

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .all-consoles-container{
        min-height: 100vh;
        width: 100%;
        padding: 1%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .add-consoles-area{
        width: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        z-index: 999;
        margin-bottom: 25px;
    }
    .adding-prompt{
        /* height: 35px;*/
        height: auto;
        width: 150px;
        padding: 6%;
        padding-left: 3%;
        padding-right: 3%;
        margin-right: 1%;
        background-color: #FFE74C;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        border-radius: 5px 5px 5px 5px;
        font-size: 0.9rem;
        font-weight: 700;
        color: #191917;
    }

    .selected-consoles-area{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .console-detail-container{
        height: 120px;
        width: 100px;
        margin-right: 10px;
        margin-bottom: 15px;
        border-radius: 15px 15px 15px 15px;
        /* background-color: transparent; */
        transition: 0.5s;
        -webkit-transition: height 0.2s;
        transition: 0.5s;
    }
    .console-detail-container:hover{
        height: 150px;
        width: 150px;
        position: sticky;
    }

    .console-detail-container p{
        font-size: 0.7rem;
        color: #f8f8f8;
    }

    /* CHOSING CONSOLE DIALOG BOX */
    .chose-console-box-container{
        background-color: #191917;
        overflow-x: hidden;
    }
    .console-tile{
        height: 75px;
        width: 95%;
        /* padding: 2%; */
        background-color: #191917;
        border-radius: 10px 10px 10px 10px;
        /* margin: 5px; */
        margin-bottom: 2px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .console-tile-info{
        height: 100px;
        width: 65%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .console-img-container{
        height: 75px;
        width: 50px;
        margin-right: 15px;
    }
    .console-profile-container{
        height: 100%;
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .console-profile-container h4{
        font-size: 0.9rem;
        color: #f8f8f8;
    }
    .console-profile-container p{
        font-size: 0.8rem;
        color: #f8f8f8;
    }
    .selected-console-container{
        background-color: #191917;
        overflow-x: hidden;
    }
    .selected-console-heading{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .selected-console-name-part{
        width: 60%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .selected-console-name-part h4{
        color: #f8f8f8;
        font-size: 1rem;
        margin-left: 20px;
    }

    .main-console-content{
        margin: 5px;
        margin-bottom: 10px;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .main-console-content p{
        font-size: 0.85rem;
        font-weight: 400;
        line-height: 22.28px;
    }

    .console-suggestions-section{
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-x: auto;
    }
}
/* FOR MOBILE DEVICES ONLY */