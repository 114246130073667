.data-deletion-page{
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.instruction-area{
    height: auto;
    width: 75%;
    display: flex;
    flex-wrap: wrap;
}

.instruction-text{
    font-size: 1rem;
    color: #f8f8f8;
    line-height: 16px;
    text-align: center;
    text-wrap: wrap;
}
.instruction-text strong{
    color: #FFE74C;
}