.finish-sign-in-page{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #080808;
    color: #f8f8f8;
}

.sign-in-email-prompt{
    height: auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 7.5px;
    justify-content: center;
    align-items: center;
}