.request-game-dialog{
    height: auto;
    width: 50%;
    padding: 1%;
    position: fixed;
    bottom: 0;
    background-color: #080808;
    border-radius: 10px 10px 10px 10px;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.request-game-dialog-text{
    text-align: left;
    font-size: 0.9rem;
    font-weight: 700;
    color: #f8f8f8;
}

.send-request-cta{
    height: 35px;
    width: 150px;
    padding: 1%;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    border: none;
    background-color: #FFE74C;
    color: #191917;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
.send-request-cta:hover{
    background-color: #ffe225;
    font-weight: 700;
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
    .request-game-dialog{
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
    }

    .request-game-dialog-text{
        text-align: left;
        font-size: 0.7rem;
        font-weight: 600;
        color: #f8f8f8;
    }
}
/* FOR MOBILE DEVICES */