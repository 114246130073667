.trending-review-area{
    height: auto;
    width: 98%;
    padding: 1%;
    background-color: #080808;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.trending-review-area-heading{
    font-size: 2rem;
    font-weight: 700;
    color: #FFE74C;
    margin: 0;
    padding: 0;
    margin-bottom: 7px;
    text-align: left;
    width: 100%;
}