.article-tile-container{
    height: 275px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px 5px 5px 5px;
    position: relative;
    transition: 0.3s height ease-in-out, 0.3s width ease-in-out;   
}
.article-tile-container:hover{
    cursor: pointer;
    box-shadow: 0 0 7px rgba(255, 255, 255, 0.5);
    height: 275px;
    width: translate(1);
}
.article-tile-overlay-div{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 2%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0808089e;
    background-blend-mode: hard-light;
    border-radius: 5px 5px 5px 5px;
}
.article-title-heading{
    width: 100%;
    text-wrap: wrap;
    font-size: 1.5rem;
    font-weight: 600;
    color: #f8f8f8;
    text-align: left;
    line-height: 30px;
    margin: 0;
    padding: 0;
}
.article-title-normal-text{
    width: 100%;
    text-wrap: wrap;
    font-size: 0.9rem;
    font-weight: 500;
    color: #f8f8f8;
    text-align: left;
    line-height: 30px;
    margin: 0;
    padding: 0;
}
.article-bg-img-container{
    height: 100%;
    width: 100%;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.article-bg-img-container img{
    height: 100%;
    width: 100%;
    border-radius: 5px 5px 5px 5px;
    aspect-ratio: 2/3;
    object-fit: cover;
}

.relevant-games-tags{
    height: auto;
    width: 100%;
    display: flex;
    gap: 7px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: auto;
}
.article-relevance-tag{
    height: auto;
    width: auto;
    padding: 1%;
    padding-left: 3%;
    padding-right: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    color: #f8f8f8;
    font-weight: 700;
    border: solid #FFE74C;
    border-width: 2.5px;
    border-radius: 25px 25px 25px 25px;
    background-color: transparent;
}
.article-tile-space-div{
    margin-top: 10px;
    margin-bottom: 10px;
}

.article-tile-actions-area{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
