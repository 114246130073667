.game-request-page{
    height: 75vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.game-request-input{
    margin: 10px;
    height: 30px;
    width: 50%;
    padding: 1%;
    background-color: transparent;
    border: solid #f8f8f8;
    border-radius: 10px 10px 10px 10px;

    font-size: 0.9rem;
    color: #f8f8f8;
    font-weight: 500;
}
.game-request-heading{
    text-align: left;
    font-size: 1.1rem;
    color: #f8f8f8;
    font-weight: 600;
    margin-bottom: 10px;
}
.game-request-cta{
    height: 45px;
    width: 150px;
    padding: 1%;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    border: none;
    background-color: #FFE74C;
    color: #191917;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
.game-request-cta:hover{
    background-color: #ffe225;
    font-weight: 700;
}

.game-request-error{
    text-align: left;
    font-size: 1rem;
    color: red;
    font-weight: 600;
    margin-bottom: 10px;
}

.successful-request-prompt{
    height: auto;
    width: 50%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.successful-request-prompt-text{
    text-align: center;
    font-size: 0.9rem;
    color: green;
    font-weight: 600;
    margin-bottom: 10px;
}
.successful-request-prompt-ticket{
    text-align: center;
    font-size: 3rem;
    color: green;
    font-weight: 800;
    margin-bottom: 10px;
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {

    .game-request-heading{
        text-align: center;
        font-size: 0.95rem;
        color: #f8f8f8;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .game-request-input{
        margin: 10px;
        width: 96%;
        padding: 1%;
        background-color: transparent;
        border: none;
        border-bottom: solid #f8f8f8;
        border-radius: 0 0 0 0;
    
        font-size: 0.9rem;
        color: #f8f8f8;
        font-weight: 500;
    }

}