.add-games-collection-title{
    height: auto;
    width: auto;
    background-color: #191917;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #f8f8f8;
}
.add-games-collection-container{
    height: auto;
    width: auto;
    background-color: #191917;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.add-games-collection-input{
    height: 35px;
    width: 96%;
    padding: 1%;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: solid #f8f8f8;
    color: #f8f8f8;
    font-size: 0.9rem;
    font-weight: 400;
}

.game-collection-list{
    height: auto;
    width: 96%;
    padding: 1%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}