.user-game-options-box{
    height: 100%;
    width: auto;
    background-color: #191917;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
}
.user-option-tile{
    height: auto;
    padding: 1%;
    width: 90%;
    /* background-color: darkmagenta; */
    margin: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}
.user-option-tile-text{
    font-size: 0.9rem;
    color: #f8f8f8;
    font-weight: 700;
    margin-left: 10px;
}
.user-option-tile-text:hover{
    font-size: 1rem;
    color: #FFE74C;
}