.user-created-forums{
    min-height: 95%;
    width: 98%;
    padding: 1%;
    /* background-color: blue; */
    /* overflow-y: auto; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.no-discussion-prompt{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.no-discussion-prompt p{
    padding: 2%;
    background-color: #FFE74C;
    font-size: 16px;
    font-weight: 600;
    color: #080808;
}
.discussion-settings-container{
    width: 100%;
    /* background-color: blueviolet; */
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.discussion-tile-action-box{
    height: 100%;
    width: 20%;
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.delete-discussion-cta{
    background-color: #FFE74C;
    width: 100px;
    padding: 2%;
    border-radius: 25px 25px 25px 25px;
    color: #080808;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 600;
}
.delete-discussion-cta:hover{
    cursor: pointer;
}

.load-more-user-discussion{
    padding-top: 2%;
    padding-bottom: 2%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.load-more-user-discussion button{
    height: 30px;
    width: 178px;
    margin-bottom: 10px;
    margin-left:27%;
    border-radius: 50px 50px 50px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.15rem;
    font-weight: 600;
    color: #FFE74C;
    background-color: transparent;
    cursor: pointer;
    border: none;
}
.load-more-user-discussion button:hover{
    background-color: #ffe74cb3;
    color: #100007;
}

/* Deletion box styling */
.user-forum-deletion-container{
    background-color: #191917;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #f8f8f8;
    font-size: 1.2rem;
    font-weight: 600;
}
.user-forum-deletion-actions{
    background-color: #191917;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #f8f8f8;
    font-size: 1.2rem;
    font-weight: 600;
}

.user-forum-deletion-button{
    height: 30px;
    width: 178px;
    margin-bottom: 10px;
    /* margin-left:27%; */
    border-radius: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 600;
    color: #080808;
    background-color: #FFE74C;
    cursor: pointer;
    border: none;
}

.user-forum-deletion-cancel-button{
    height: 30px;
    width: 45px;
    margin-bottom: 10px;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 600;
    color: #FFE74C;
    background-color: transparent;
    cursor: pointer;
    border: none;
}
.user-forum-deletion-cancel-button:hover{
    height: 30px;
    width: 178px;
    color: #080808;
    background-color: #ffe74cb3;
}