.next-article-cta{
    height: auto;
    width: auto;
    box-sizing: border-box;
    padding: 1.5%;
    padding-left: 3%;
    padding-right: 3%;
    display: flex;
    /* gap: 5px; */
    justify-content: space-between;
    align-items: center;
    border-radius: 5px 5px 5px 5px;
    border: none;
    outline: none;
    background-color: #191917;
    box-shadow: 0 0 5px rgba(255,255,255,0.3);
    color: #f8f8f8;
    transition: 0.3s box-shadow ease-in-out;
}

.next-article-cta:hover{
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

.next-article-cta a{
    display: block;
    text-decoration: none;
    height: auto;
    width: 100%;
}

.next-article-cta-texts{
    height: 100%;
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    color: #f8f8f8;
}

.forward-icon-cta{
    flex: 1;
    color: #FFE74C;
}

.next-article-cta-heading{
    text-align: left;
    font-size: 0.8rem;
    font-weight: 700;
    color: #f8f8f8;
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
}

.next-article-cta-text{
    text-align: left;
    font-size: 0.8rem;
    color: #f8f8f8;
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
}