.user-wishlist-container{
    min-height: 100vh;
    width: 98%;
    padding: 1%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
}
.user-wishlist-container::-webkit-scrollbar{
    display: none;
}

.user-wishlist-actions{
    height: auto;
    width: auto;
    padding: 1%;
    margin-bottom: 7px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 999;
}

.add-game-to-wishlist-cta{
    height: 35px;
    width: 150px;
    border: none;
    outline: none;
    border-radius: 5px 5px 5px 5px;
    background-color: #FFE74C;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #080808;
    font-size: 0.9rem;
    font-weight: 800;
    cursor: pointer;
}

.user-wishlist-box{
    height: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.wishlist-message-screen{
    height: 75vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wishlist-message-screen p{
    padding: 2%;
    background-color: #FFE74C;
    font-size: 16px;
    font-weight: 600;
    color: #080808;
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {

    .user-wishlist-container{
        min-height: 100vh;
        width: 98%;
        padding: 1%;
        /* background-color: mediumslateblue; */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .user-wishlist-box{
        height: 100%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-y: auto;
    }

    .user-wishlist-actions{
        height: auto;
        width: auto;
        padding: 1%;
        margin-bottom: 7px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 999;
    }
    
    .add-game-to-wishlist-cta{
        height: 35px;
        width: 150px;
        border: none;
        outline: none;
        border-radius: 5px 5px 5px 5px;
        background-color: #FFE74C;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #080808;
        font-size: 0.9rem;
        font-weight: 800;
        cursor: pointer;
    }

}

/* FOR MOBILE DEVICES */
/* @media only screen and (max-width: 600px) {
    .user-wishlist-container{
        height: 100%;
        width: 100%;
        padding: 1%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-y: auto;
    }
    .wishlist-tile{
        height: 150px;
        width: 120px;
        margin: 2px;
        border-radius: 15px 15px 15px 15px;
        background-color: #20181bce;
        position: relative;
        transition: 0.5s;
        -webkit-transition: height 0.2s;
        transition: 0.5s;
        cursor: pointer;
    }

    .actions-container{
        height: 60%;
        width: 120px;
        border-radius: 15px 15px 15px 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: #191917;
        align-items: center;
        color: #f8f8f8;
        font-size: 0.9rem;
        font-weight: 500;
    
        transition: 0.3s;
        -webkit-transition: height 0.3s;
        transition: 0.3s;
    
        position: absolute;
    }

    .action-tile{
        height: auto;
        width: 100%;
        padding-left: 2%;
        padding-right: 2%;
        margin-bottom: 5px;
        border-radius: 15px 15px 15px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: #f8f8f8;
        font-size: 0.8rem;
        font-weight: 500;
    
        transition: 0.3s;
        -webkit-transition: height 0.3s;
        transition: 0.3s;
    }
    .action-tile:hover{
        width: 100%;
        background-color: #FFE74C;
        color: #191917;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
    }
} */
/* FOR MOBILE DEVICES */