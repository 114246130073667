.trending-forum-carousel-container{
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.trending-forum-carousel{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #080808;
    position: relative;
}
.trending-forum-carousel-nav-btns{
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    outline: none;
}

.forum-carousel-content{
    width: 95%;
    display: flex;
    overflow: hidden;
}

.forum-carousel-item{
    min-width: 95%;
    transition: transform 0.5s ease-in-out;
    opacity: 0;
    display: none;
}

.forum-carousel-item.active{
    opacity: 1;
    display: block;
}

.trending-forum-container-mobile-navigations{
    display: none;
}

@media only screen and (max-width: 600px) {
    .trending-forum-carousel{
        width: 100%;
    }

    .trending-forum-carousel-nav-btns {
        /* top: 10px; */
        /* transform: none;
        position: absolute;
        top: 25%;
        transform: translateY(-25%);
        z-index: 10; */
        display: none;
    }

    /* .trending-forum-carousel-nav-btns.left {
        display: none;
    }

    .trending-forum-carousel-nav-btns.right {
        height: auto;
        width: auto;
        padding: 2%;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 10px;
    } */

    .forum-carousel-content {
        min-width: 100vw;
    }

    .trending-forum-container-mobile-navigations{
        height: auto;
        /* background-color: mediumaquamarine; */
        width: 95%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .trending-forum-mobile-nav-btns{
        height: auto;
        width: auto;
        padding: 2%;
        margin-right: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #191917;
        border-radius: 50%;
        border: none;
        outline: none;
    }
}