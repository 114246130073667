.forum-workspace-container{
    height: 96%;
    width: 96%;
    padding: 1%;
    /* background-color: aquamarine; */
    /* overflow-y: auto; */
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.forum-workspace-tabs{
    height: 50px;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.user-forums-tab{
    border: none;
    outline: none;
    height: 50px;
    width: 150px;
    margin-right: 10px;
    padding: 1% 1% 1% 1%;
    border-radius: 5px 5px 5px 5px;
    background-color: black;
    font-size: 0.9rem;
    font-weight: 500;
    color: white;
    transition: 0.3s;
    -webkit-transition: width 0.3s;
    transition:0.3s;
}

.user-forums-tab.selected{
    border: none;
    outline: none;
    border: solid yellow;
    height: 50px;
    width: 200px;
    padding: 1% 1% 1% 1%;
    background-color: black;
    font-size: 0.95rem;
    font-weight: 700;
    color: white;
}