.forum-discussion-tile{
    height: auto;
    padding: 1%;
    width: 100%;
    border-radius: 10px 10px 10px 10px;
    background-color: #191917;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.forum-discussion-tile img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.discussion-user{
    height: 50px;
    width: 98%;
    padding-left: 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.discussion-user-profile{
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.user-image{
    height: 35px;
    width: 35px;
    border-radius: 50% 50% 50% 50%;
    margin-right: 10px;
    background-color: #FFE74C;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-image p{
    font-size: 0.95rem;
    font-weight: 500;
    color: #080808;
}
.user-image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50% 50% 50% 50%;
}
.discussion-user-details{
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}
.discussion-user-details p{
    margin: 0;
    padding: 0;
    color: #f8f8f8;
    font-size: 0.8rem;
    font-weight: 600;
}
.discussion-spoiler-prompt{
    height: 90%;
    width: 15%;
    border-bottom-left-radius: 5px;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 700;
    color: #f8f8f8;
}
.discussion-main-content{
    height: 50%;
    width: 100%;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.discussion-voting-area{
    height: 100%;
    width: auto;
    padding-left: 1%;
    padding-right: 1%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.discussion-voting-area p{
    font-size: 1rem;
    font-weight: 800;
    color: #f8f8f8;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0;
}

.discussion-texts-area{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.discussion-game-name{
    font-size: 0.9rem;
    font-weight: 700;
    color: #FFE74C;
    margin-bottom: 7px;
    cursor: pointer;
}

.discussion-title{
    width: 100%;
    text-align: left;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
    color: #f8f8f8;
}

.discussion-desc{
    height: 75%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.discussion-desc img{
    margin-top: 10px;
    height: 50%;
    width: 50%;
    object-fit: cover;
    border-radius: 10px 10px 10px 10px;
}
.discussion-desc p{
    font-size: 0.95rem;
    font-weight: 500;
    text-align: left;
    padding: 0;
    margin: 0;
    color: #f8f8f8;
}

.discussion-tagged-games{
    margin-top: 10px;
    margin-bottom: 10px;
    height: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.discussion-tagged-game-tile{
    height: auto;
    width: auto;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-right: 3%;
    padding-left: 3%;
    margin: 5px;
    border-radius: 25px 25px 25px 25px;
    background-color: #FFE74C;
    color: #080808;
    font-size: 0.75rem;
    font-weight: 700;
    cursor: pointer;
}


.title-separator{
    height: 1px;
    width: 98%;
    margin-top: 7px;
    margin-bottom: 7px;
    border-radius: 10px 10px 10px 10px;
    border-color: #f8f8f82b;
    background-color: #f8f8f82b;
}

/* For mobile devices */
@media only screen and (max-width: 600px) {
    .forum-discussion-tile{
        height: auto;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: #191917;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .discussion-spoiler-prompt{
        height: 90%;
        width: 30%;
        border-bottom-left-radius: 5px;
        background-color: red;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9rem;
        font-weight: 700;
        color: #f8f8f8;
    }

    .discussion-desc img{
        margin-top: 10px;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 10px 10px 10px 10px;
    }
}