.user-login-container{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.welcome-banner{
    width: 27.5%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.welcome-banner-logo-container{
    height: 50px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.welcome-banner-logo-container img{
    height: 100%;
    width: 100%;
    aspect-ratio: 2/3;
    object-fit: contain;
}


.login-welcome-msg{
    font-size: 2.5rem;
    font-weight: 500;
    color: #f8f8f8;
    margin: 0;
    padding: 0;
    /* margin-bottom: 30px; */
    text-wrap: wrap;
    text-align: left;
}

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .user-login-container{
        height: 90vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .welcome-banner{
        width: 75%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }

    .welcome-banner-logo-container{
        height: 50px;
        width: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .login-welcome-msg{
        font-size: 1.65rem;
        font-weight: 500;
        color: #f8f8f8;
        margin: 0;
        padding: 0;
        text-wrap: wrap;
        text-align: left;
    }
}
/* FOR MOBILE DEVICES ONLY */