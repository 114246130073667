/* New discussion creation styling. */
.create-discussion-container{
    height: 90vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.new-discussion-form{
    min-height: 350px;
    width: 100%;
    /* background-color: green; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

/* .new-discussion-form input[type='text']{
    width: 90%;
    height: 25px;
    padding: 2%;
    border: solid #f8f8f8;
    border-width: 1px;
    border-radius: 5px 5px 5px 5px;
    background-color: transparent;
    color: #f8f8f8;
    font-size: 0.9rem;
} */
.forum-title-area{
    width: 90%;
    height: 25px;
    padding: 2%;
    border: solid #f8f8f8;
    border-width: 1px;
    border-radius: 5px 5px 5px 5px;
    background-color: transparent;
    color: #f8f8f8;
    font-size: 0.9rem;
}
.forum-discussion-description{
    width: 95%;
    min-height: 100px;
    padding: 2%;
    background-color: transparent;
    color: #f8f8f8;
    font-size: 0.9rem;
}
/* .new-discussion-form textarea{
    width: 90%;
    height: 25px;
    padding: 2%;
    border: solid #f8f8f8;
    border-width: 1px;
    border-radius: 5px 5px 5px 5px;
    background-color: transparent;
    color: #f8f8f8;
    font-size: 0.9rem;
    font-family:sans-serif;
} */
.forum-description-area{
    margin: 10px;
    width: 90%;
    height: 25px;
    padding: 2%;
    border: solid #f8f8f8;
    border-width: 1px;
    border-radius: 5px 5px 5px 5px;
    background-color: transparent;
    color: #f8f8f8;
    font-size: 0.9rem;
    font-family:sans-serif;
}

.tag-games-area{
    min-height: 50px;
    max-height: 150px;
    width: 94%;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    /* background-color: mediumpurple; */
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
}

.add-rel-game-cta{
    margin-bottom: 10px;
    height: 35px;
    width: auto;
    padding: 0.5%;
    padding-left: 3%;
    padding-right: 3%;
    border-radius: 25px 25px 25px 25px;
    border: solid #FFE74C;
    border-width: 1.5px;
    background-color: #080808;
    color: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 600;
}
.add-rel-game-cta:hover{
    cursor: pointer;
    background-color: #262626;
}

.search-input-div{
    height: auto;
    width: 95%;
    margin-bottom: 10px;
}
.game-tag-dialog{
    height: auto;
    background-color: #191917;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.game-tag-dialog::-webkit-scrollbar{
    display: none;
}
.game-tag-dialog-actions{
    height: auto;
    width: 98%;
    padding: 2%;
    background-color: #191917;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.game-tag-selected-container{
    min-height: 0;
    max-height: 150px;
    width: 95%;
    padding: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.game-tag-cta-container{
    height: auto;
    width: 95%;
    padding: 2%;
    display: flex;
    justify-content: flex-end;
}
.game-tag-cta{
    height: auto;
    width: 50px;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    padding-left: 3%;
    padding-right: 3%;
    background-color: #FFE74C;
    color: #080808;
    font-size: 0.9rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    cursor: pointer;
}
.tag-game-tile{
    height: auto;
    margin-bottom: 7px;
    width: 94%;
    padding: 1.5%;
    padding-left: 3%;
    padding-right: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #080808;
    color: #f8f8f8;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
}
.tag-game-tile.selected{
    background-color: #FFE74C;
    color: #080808;
}
.tag-game-tile:hover{
    background-color: #FFE74C;
    color: #080808;
}
.tagged-game-list-tile{
    height: auto;
    width: auto;
    padding: 1%;
    padding-left: 3%;
    padding-right: 3%;
    margin-left: 7px;
    margin-right: 7px;
    /* margin-bottom: 7px; */
    border-radius: 25px 25px 25px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #080808;
    color: #f8f8f8;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
}

.forum-spoiler-check-area{
    width: 95%;
    /* background-color: yellowgreen; */
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #FFE74C;
}

.forum-spoiler-check{
    height: 10px;
    width: 10px;
    border-radius: 2px 2px 2px 2px;
    border: solid #ffff00;
    border-width: 2px;
    background-color: transparent;
    margin-right: 7px;
}
.forum-spoiler-check.active{
    background-color: #ffff00;
}

.forum-post-area{
    height: 45px;
    width: 95%;
    display: flex;
    justify-content: flex-end;
}

.add-forum-discussion-cta{
    height: 45px;
    width: 20%;
    padding: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    border: none;
    background-color: #FFE74C;
    color: #191917;
    font-size: 14px;
    font-weight: 600;
}

/* For mobile devices */
@media only screen and (max-width: 600px) {
    .create-discussion-container{
        padding: 2%;
        height: 75vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
}