.search-result-box{
    height: auto;
    width: 100vw;
    background-color: #191917;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.search-result-box::-webkit-scrollbar{
    display: none;
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {

    .search-result-box{
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }

}