/* #080808 */
.password-reset-container{
    height: 90.95vh;
    width: 100%;
    background-color: #080808;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f8f8f8;
}

.password-reset-inputs-container{
    height: 400px;
    width: 50%;
    /* background-color: violet; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.password-reset-message-prompt{
    font-size: 28px;
    font-weight: 500;
    color: #FFE74C;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
}

.password-reset-input{
    height: 30px;
    width: 75%;
    padding: 2%;
    margin-bottom: 20px;
    background-color: transparent;
    border: solid #EEE5E9;
    border-radius: 10px 10px 10px 10px;
    outline: none;
    color: #f8f8f8;
    font-size: medium;
    font-weight: 500;
}

.reset-password-cta{
    height: 50px;
    width: 80%;
    padding: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    border: none;
    background-color: #FFE74C;
    color: #191917;
    font-size: medium;
    font-weight: bolder;
    margin-bottom: 20px;
}
.reset-password-cta:hover{
    background-color: #feec79;
    cursor: pointer;
}

.pass-reset-status-text{
    color: #080808;
}

.pass-reset-login{
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    color: #FFE74C;
    font-size: 14px;
    font-weight: 600;
    cursor:pointer;
}

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .password-reset-container{
        height: 95vh;
        width: 100%;
        background-color: #080808;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #f8f8f8;
    }
    .password-reset-inputs-container{
        height: 400px;
        width: 100%;
        /* background-color: violet; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
/* FOR MOBILE DEVICES ONLY */