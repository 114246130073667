.dp-loader{
    height: 250px;
    width: 250px;
    border-radius: 125px 125px 125px 125px;
    background-color: #20181bce;
}

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .dp-loader{
        height: 100px;
        width: 100px;
        border-radius: 120px 120px 120px 120px;
        background-color: #20181bce;
    }
}
/* FOR MOBILE DEVICES ONLY */