.hottest-game-container{
    height: 375px;
    width: 98%;
    margin-bottom: 20px;
    padding-left: 1%;
    padding-right: 1%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.hottest-game-heading{
    width: 100%;
    text-align: left;
    margin-bottom: 7px;
    font-size: 1.7rem;
    font-weight: 800;
    color: #FFE74C;
}

.hottest-game-subheading{
    margin-bottom: 25px;
    font-size: 1.2rem;
    font-weight: 500;
    color: #f8f8f8;
}
.hottest-game-gamepage-cta{
    width: auto;
    height: auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hottest-game-txt-btn{
    font-size: 1rem;
    font-weight: 700;
    color: #f8f8f8;
    cursor: pointer;

    transition: 0.5s;
    -webkit-transition: height 0.5s;
    transition: 0.5s;
}
.hottest-game-txt-btn:hover{
    font-size: 1.5rem;
    font-weight: 800;
    margin-right: 15px;
    color: #f8f8f8;
    cursor: pointer;
}

.hottest-game-loading{
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 10px 10px;
    background-color: #191917;
}

.hottest-game-area{
    height: 100%;
    width: 90%;
    border-radius: 10px 10px 10px 10px;
    background-color: #080808;
    position: relative;
}
.hottest-game-media-part{
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 10px 10px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hottest-game-media-part img{
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 10px 10px;
    object-fit: cover;
    object-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hottest-game-media-part video{
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 10px 10px;
    object-fit: cover;
    object-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hottest-game-area-actions{
    height: 20%;
    width: 50%;
    padding-left: 2%;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.hottest-game-text-part{
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 10px 10px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.247);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    cursor: pointer;
}
.hottest-game-text-part.show{
    opacity: 1;
    transition: opacity 0.5s;
}
.hottest-game-text-part-heading{
    width: 100%;
    text-align: center;
    margin-bottom: 7px;
    font-size: 1.7rem;
    font-weight: 800;
    color: #FFE74C;
}
.banner-volume-control-container{
    height: 24px;
    width: 24px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    margin: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-volume-btn{
    color: #f8f8f8;
    height: 30px;
    width: 30px;

    transition: 0.5s;
    -webkit-transition: width 0.5s;
    transition: 0.5s;
}

.banner-volume-btn:hover{
    height: 35px;
    width: 35px;
    font-weight: 800;
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
    .hottest-game-container{
        width: 100%;
        margin-bottom: 20px;
        margin-left: 5%;
        padding-left: 1%;
        padding-right: 1%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .hottest-game-area{
        width: 95%;
    }

    .hottest-game-area-actions{
        height: auto;
        width: 100%;
        padding-right: 2%;
        padding-bottom: 0.75%;
        bottom: 0;
        right: 0;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .banner-volume-control-container{
        margin-right: 5%;
    }
}