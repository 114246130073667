.user-wishlist-tile{
    height: 300px;
    width: 300px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px 5px 5px 5px;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex:auto; */
}
.user-wishlist-tile-image-container{
    height: 100%;
    width: 100%;
    border-radius: 5px 5px 5px 5px;
    position: relative;
}
.user-wishlist-tile-image-container img{
    height: 100%;
    width: 100%;
    border-radius: 5px 5px 5px 5px;
    object-fit: cover;
    /* position: absolute; */
}

.user-wishlist-tile-loader{
    height: 300px;
    width: 300px;
    /* width: auto; */
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px 5px 5px 5px;
    background-color: #080808
}


/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {

    .user-wishlist-tile-loader{
        height: 115px;
        width: 115px;
        /* width: auto; */
        margin-right: 7px;
        margin-bottom: 5px;
        border-radius: 10px 10px 10px 10px;
        background-color: #080808
    }

    .user-wishlist-tile{
        height: 115px;
        width: 115px;
        /* width: auto; */
        margin-right: 3px;
        margin-bottom: 5px;
        border-radius: 10px 10px 10px 10px;
        background-color: rgba(0, 0, 0, 0.75);
        display: flex;
        justify-content: center;
        align-items: center;
        /* flex:auto; */
    }
}