.profile-user-reviews-container{
    min-height: 95%;
    width: 96%;
    padding: 1%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
}

.load-more-container{
    height: 30px;
    width: 100%;
    padding: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.load-more-button{
    height: 30px;
    width: 178px;
    margin-bottom: 10px;
    border-radius: 50px 50px 50px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #f8f8f8;
    cursor: pointer;
}

.load-more-button:hover{
    background-color: #ffe74cb3;
    color: #100007;
}

.no-profile-review-area{
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.no-profile-review-area p{
    padding: 2%;
    background-color: #FFE74C;
    color: #080808;
    font-weight: 600;
}

.user-profile-game-rev-box{
    margin: 5px;
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* background-color: #191917; */
    border-radius: 10px 10px 10px 10px;
}
.user-profile-rev-game-name{
    margin-bottom: 5px;
    margin-top: 10px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFE74C;
    border: none;
    outline: none;
    border-radius: 25px 25px 25px 25px;
    color: #080808;
    font-size: 0.95rem;
    font-weight: 700;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    padding-left: 2%;
    padding-right: 2%;
}

/* LOADER */

.user-profile-rev-loader{
    height: 95%;
    width: 96%;
    padding: 1%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.user-profile-rev-tile-loader{
    min-height: 100px;
    width: 75%;
    padding: 1%;
    background-color: #191917;
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.tile-game-name-loader{
    height: 20px;
    width: 25%;
    background-color: #3e3e39;
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 10px;
}
.tile-game-date-loader{
    height: 10px;
    width: 15%;
    background-color: #3e3e39;
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 10px;
}
.tile-game-score-loader{
    height: 24px;
    width: 10%;
    background-color: #ffe74c;
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 10px;
}
.tile-game-text-loader{
    height: 45px;
    width: 95%;
    background-color: #3e3e39;
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 10px;
}
.tile-game-reactions-loader{
    height: 15px;
    width: 35%;
    background-color: #3e3e39;
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 10px;
}
/* LOADER */

/* FOR MOBILE USERS ONLY */
@media only screen and (max-width: 600px) {
    .profile-user-reviews-container{
        min-height: 95%;
        width: 98%;
        padding: 1%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .user-profile-rev-loader{
        height: 95%;
        width: 100%;
        padding: 1%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .user-profile-game-rev-box{
        background-color: #080808;
    }
}
/* FOR MOBILE USERS ONLY */