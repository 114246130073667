.App {
  min-height: 100vh;
  max-width: 100vw;
  text-align: center;
  background-color: #080808;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vertical-space-div{
  height: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn{
  height: 5vh;
  padding: 2%;
  /* width: 150px; */
  width: 25vh;
  margin-right: 5px;
  background-color: #ffff00;
  /* border: none; */
  outline: none;
  border-radius: 50px 50px 50px 50px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #080808;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.btn:hover{
  height: 4vh;
  background-color: #f8f8f8;
  color: #191917;
  transition: height 0.3s;
  -webkit-transition: height 0.5s;
}

.negative-btn{
  height: 5vh;
  padding: 1%;
  /* width: 150px; */
  width: auto;
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
  /* border: none; */
  outline: none;
  border: none;
  border-radius: 50px 50px 50px 50px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #f8f8f8;
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab{
  height: 7vh;
  width: auto;
  padding: 0.5%;
  background-color: #181817;
  border: none;
  outline: none;
  color: #f8f8f8;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  margin-right: 10px;
}
.tab.active{
  border-bottom: solid #ffff00;
  border-width: 3px;
}

.sectional{
  margin-top: 20px;
  margin-bottom:20px;
  height: 0px;
  width: 75%;
  border: solid #2e2e2ec3;
  border-width: 1px;
  border-radius: 10px 10px 10px 10px;
}

.filter{
  height: 100%;
  width: 15vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
}
.filter p{
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  color: yellow;
  cursor: pointer;
}
.check{
  height: 2.5vh;
  width: 15px;
  background-color: transparent;
  border: solid yellow;
  border-width: 2px;
  border-radius: 10% 10% 10% 10%;
  margin-right: 5px;
}
.check.active{
  background-color: yellow;
}

@media only screen and (max-width: 600px) {
  .vertical-space-div{
    height: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}