.genre-page-container{
    margin: 10px;
    box-sizing: border-box;
    min-height: 100vh;
    width: 98%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.all-genres-list-container{
    height: auto;
    width: 100%;
    overflow-x: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    scroll-behavior: smooth;
    /* For firefox browser */
    scrollbar-color: #FFE74C transparent; /* Thumb color and track color */
    scrollbar-width: thin;
}

.all-genres-list-container::-webkit-scrollbar{
    height: 20px;
}

.all-genres-list-container::-webkit-scrollbar-track{
    background: transparent;
}

.all-genres-list-container::-webkit-scrollbar-thumb{
    color: #FFE74C;
    border-radius: 10px 10px 10px 10px;
}

.all-genre-tile{
    height: 35px;
    white-space: nowrap;
    width: auto;
    padding-left: 1%;
    padding-right: 1%;
    margin: 5px;
    border: none;
    outline: none;
    background-color: #191917;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
    color: #f8f8f8;
    cursor: pointer;
}
.all-genre-tile.selected{
    background-color: #FFE74C;
    color: #080808;
}

.selected-genre-details-container{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.genre-page-ad-component-1{
    height: 100vh;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f8f8f8;
}

.genre-page-ad-component-2{
    height: 100vh;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f8f8f8;
}

.genre-about-container{
    height: 100%;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

}

.genre-page-heading{
    font-size: 2.5rem;
    font-weight: 700;
    color: #f8f8f8;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
    text-align: left;
}

.genre-page-sub-heading{
    font-size: 1.5rem;
    font-weight: 600;
    color: #FFE74C;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
}

.genre-games-list-component{
    height: auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
    gap: 7px;
    align-items: center;
    flex-wrap: wrap;
}

.genre-game-tile{
    height: 150px;
    width: 150px;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: transform 0.3s ease-in-out, z-index 0.3s, width 0.3s ease-in-out, height 0.3s ease-in-out;
}
.genre-game-tile img{
    height: 100%;
    width: 100%;
    aspect-ratio: 2/3;
    object-fit: cover;
    border-radius: 5px 5px 5px 5px;
}
.genre-game-tile:hover{
    cursor: pointer;
    height: 300px;
    z-index: 1;
    transform: scale(1);
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
    .genre-page-ad-component-1{
        display: none;
    }   
    .genre-page-ad-component-2{
        display: none;
    }

    .selected-genre-details-container{
        height: 100%;
        width: 100%;
        padding: 2%;
    }
    .genre-games-list-component{
        justify-content: flex-start;
        gap: 5px;
    }
    .genre-game-tile{
        height: 96px;
        width: 96px;
    }
}