.privacy-policy-container{
    min-height: 100vh;
    width: 100%;
    background-color: #080808;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.pp-space-div{
    height: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.privacy-policy-area{
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.pp-heading{
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    font-size: 2rem;
    font-weight: 700;
    color: #f8f8f8;
}
.pp-subheading{
    margin: 0;
    padding: 0;
    font-size: 1.25rem;
    font-weight: 800;
    color: #f8f8f8;
}
.pp-bold-text{
    margin: 0;
    padding: 0;
    font-size: 0.95rem;
    font-weight: 800;
    color: #f8f8f8;
    cursor: pointer;
}
.pp-normal-text{
    margin: 0;
    padding: 0;
    margin-top: 10px;
    text-align: left;
    line-height: 24px;
    font-size: 0.9rem;
    font-weight: 500;
    color: #f8f8f8;
}

.pp-ending-line{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .privacy-policy-area{
        width: 95%;
    }
}