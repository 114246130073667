.user-wishlist-options-box{
    height: auto;
    width: auto;
    background-color: #191917;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.user-wishlist-option-tile{
    height: auto;
    padding: 1%;
    width: auto;
    margin: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}
.user-wishlist-option-tile-text{
    font-size: 0.9rem;
    color: #f8f8f8;
    font-weight: 700;
    margin-left: 10px;
}
.user-wishlist-option-tile-text:hover{
    font-size: 1rem;
    color: #FFE74C;
}