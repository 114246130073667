.all-games-loader-container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;
}

.preview-loader{
    height: 300px;
    width: 200px;
    margin: 5px;
    border-radius: 15px 15px 15px 15px;
    background-color: #20181bce;

    transform: scale(1);
    animation: pulse-preview-loader 2s infinite;
}

@keyframes pulse-preview-loader {
    0% {
      transform: scale(0.85);
      box-shadow: 0 0 0 0 #20181bce;
    }
    
    70% {
      transform: scale(0.90);
      box-shadow: 0 0 0 10px #20181bce;
    }
    
    100% {
      transform: scale(0.85);
      box-shadow: 0 0 0 0 #20181bce;
    }
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
  .preview-loader{
      height: 150px;
      width: 120px;
      margin: 2px;
      border-radius: 15px 15px 15px 15px;
      background-color: #20181bce;

      transform: scale(1);
      animation: pulse-preview-loader 2s infinite;
  }
  @keyframes pulse-preview-loader {
    0% {
      transform: scale(0.65);
      box-shadow: 0 0 0 0 #20181bce;
    }
    
    70% {
      transform: scale(0.70);
      box-shadow: 0 0 0 10px #20181bce;
    }
    
    100% {
      transform: scale(0.65);
      box-shadow: 0 0 0 0 #20181bce;
    }
}
}
/* FOR MOBILE DEVICES */