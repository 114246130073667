.main-game-page-container{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #f8f8f8;
}
.main-game-header{
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.main-game-header img{
    position: absolute;
    height: 100%;
    width: 100%;
    aspect-ratio: 4/5;
    object-fit: cover;
    object-position: center;
}

.main-game-banner-overlay{
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
}

.main-game-about-details{
    height: 100%;
    width: 50%;
    box-sizing: border-box;
    padding: 2%;
    display: flex;
    gap: 25px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.main-game-page-heading{
    color: #FFE74C;
    text-align: left;
    padding: 0;
    margin: 0;
    text-wrap: wrap;
}

.main-game-page-heading-2{
    color: #f8f8f8;
    text-align: left;
    padding: 0;
    margin: 0;
    text-wrap: wrap;
}

.main-game-text{
    font-size: 0.95rem;
    font-weight: 500;
    color: #f8f8f8;
    margin: 0;
    padding: 0;
    text-align: left;
    text-wrap: wrap;
    line-height: 24px;
}

.main-game-info-area{
    height: auto;
    width: 85%;
    display: flex;
    gap: 7px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.main-game-info-box{
    height: auto;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 7px;
    justify-content: flex-start;
    align-items: flex-start;
}

.main-game-platform-area{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    align-items: flex-start;
}

.main-game-platform-btn{
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 0.95rem;
    color: #f8f8f8;
    font-weight: 500;
    padding: 0;
    transition: 0.3s font-size ease-in-out;
}
.main-game-platform-btn:hover{
    cursor: pointer;
    color: #FFE74C;
    font-size: 1rem;
}

.main-game-profile-action-box{
    height: auto;
    width: 100%;
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
}
.main-game-action-btn{
    height: 35px;
    padding: 1.5%;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 5px 5px 5px 5px;
    background-color: #FFE74C;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #080808;
    font-size: 0.9rem;
    font-weight: 550;
}

.main-game-text-btn{
    padding: 0;
    margin: 0;
    height: 35px;
    border-radius: 5px 5px 5px 5px;
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    color: #FFE74C;
    font-size: 0.9rem;
    font-weight: 550;
    transition: 0.3s font-size ease-in;
}
.review-btn-arrow-icon{
    color: #ffe74c;
    font-size: 0.9rem;
    transition: font-size 0.3s ease-in;
}
.main-game-text-btn:hover{
    font-size: 1rem;
    cursor: pointer;
}

.main-game-main-area{
    box-sizing: border-box;
    height: auto;
    width: 100%;
    padding: 1%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.main-game-ad-section-1{
    flex: 1;
    /* background-color: mediumpurple; */
}
.main-game-ad-section-2{
    flex: 1;
    /* background-color: mediumvioletred; */
}
.main-game-info-section{
    height: 100%;
    flex: 3;
    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.main-game-additional-info-area{
    height: auto;
    width: 100%;
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    align-items: flex-start;
}
.main-game-thumb-img-container{
    height: 225px;
    width: 150px;
    border-radius: 5px 5px 5px 5px;
    background-color: #FFE74C;
}
.main-game-thumb-img-container img{
    height: 100%;
    width: 100%;
    aspect-ratio: 4/5;
    object-fit: cover;
    border-radius: 5px 5px 5px 5px;
}

.main-game-add-info-txt-area{
    width: 100%;
    height: auto;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.main-game-synopsis-area{
    height: auto;
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-game-additional-info-section{
    height: auto;
    flex: 1;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.main-game-min-requirement{
    box-sizing: border-box;
    padding: 2%;
    height: auto;
    width: 100%;
    display: flex;
    gap: 7px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: solid #FFE74C;
    border-radius: 5px 5px 5px 5px;
    border-width: 2px;
}

.main-game-rev-section{
    box-sizing: border-box;
    margin: 5px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.main-game-rev-section-heading{
    padding: 0;
    margin: 0;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-game-gallery-section{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    justify-content: flex-start;
    align-items: flex-start;
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
    .main-game-header{
        height: 65vh;
    }
    .main-game-header img{
        position: absolute;
        height: 100%;
        width: 100%;
        aspect-ratio: 2/3;
        object-fit: cover;
        object-position: center;
    }
    .main-game-banner-overlay{
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.55);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
    }

    .main-game-about-details{
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 4%;
        display: flex;
        gap: 25px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .main-game-main-area{
        box-sizing: border-box;
        height: auto;
        width: 100%;
        padding: 1%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .main-game-info-section{
        height: 100%;
        box-sizing: border-box;
        padding: 2%;
        flex: 3;
        display: flex;
        gap: 15px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .main-game-info-area{
        height: auto;
        width: 85%;
        display: flex;
        gap: 7px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    .main-game-thumb-img-container{
        display: none;
    }
}

/* For tablets */
@media only screen and (min-width: 600px) and (max-width: 1279px){
    .main-game-header{
        height: 45vh;
    }
    .main-game-ad-section-1{
        display: none;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1024px) and (max-height: 600px) {
    /* Your styles for devices with 1024x600 dimensions */
    .main-game-header{
        height: 80vh;
    }
}

@media only screen and (min-width: 768px) and (max-width: 768px) and (max-height: 1024px) {
    /* Your styles for devices with 1024x600 dimensions */
    .main-game-header{
        height: 50vh;
    }
}