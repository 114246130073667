.user-game-tile{
    height: 200px;
    width: 125px;
    margin-right: 7px;
    margin-bottom: 7px;
    border-radius: 5px 5px 5px 5px;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-game-tile-image-container{
    height: 100%;
    width: 100%;
    border-radius: 5px 5px 5px 5px;
    position: relative;
}
.user-game-tile-image-container img{
    height: 100%;
    width: 100%;
    border-radius: 5px 5px 5px 5px;
    object-fit: cover;
    /* position: absolute; */
}
.currently-playing-tag{
    height: 10%;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #FFE74C;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    font-size: 0.85rem;
    color: #080808;
    font-weight: 700;
}

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .user-game-tile{
        height: 100px;
        width: 118px;
        margin-right: 7px;
        margin-bottom: 5px;
        border-radius: 5px 5px 5px 5px;
        background-color: rgba(0, 0, 0, 0.75);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .currently-playing-tag{
        height: 20%;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #FFE74C;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        font-size: 0.80rem;
        color: #080808;
        font-weight: 700;
    }
}