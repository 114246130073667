.user-search-game-tile{
    height: auto;
    width: 95%;
    padding: 1%;
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.user-search-game-tile p{
    font-size: 0.85rem;
    font-weight: 600;
    color: #f8f8f8;
}

.user-search-game-tile-img-box{
    height: 75px;
    width: 150px;
    border-radius: 5px 5px 5px 5px;
}
.user-search-game-tile-img-box img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px 5px 5px 5px;
}
.add-game-to-collection-box-cta{
    height: 35px;
    width: 100px;
    background-color: #FFE74C;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #080808;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
}

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .user-search-game-tile{
        height: 75px;
        width: 98%;
        /* padding: 2%; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }
    .user-search-game-tile-img-box{
        height: 50px;
        width: 100px;
        border-radius: 10px 10px 10px 10px;
        margin-right: 7px;
    }
    .add-game-to-collection-box-cta{
        height: 35px;
        width: auto;
        padding-left: 5%;
        padding-right: 5%;
        background-color: #ffe74c;
        border-radius: 10px 10px 10px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #080808;
        font-size: 0.9rem;
        font-weight: 600;
    }

    .user-search-game-tile p{
        text-align: center;
        font-size: 0.7rem;
        font-weight: 600;
        color: #f8f8f8;
    }
}