.auth-providers-container{
    height: auto;
    width: 50%;
    padding: 1%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.auth-provider{
    height: 60px;
    width: 100%;
    background-color: #191917;
    border-radius: 10px 10px 10px 10px;
    /* border-radius: 50%; */
    /* background-color: transparent; */
    border: none;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.auth-provider:hover{
    box-shadow: 0 0 5px #f8f8f8;
}

.auth-provider-logo-container{
    height: 50px;
    width: 50px;
    background-color: #080808;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-provider-logo-container img{
    height: 65%;
    width: 65%;
    object-fit: cover;
}

.auth-provider-txt{
    font-size: 1rem;
    font-weight: 500;
    color: #f8f8f8;
    text-wrap: wrap;
}

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .auth-providers-container{
        width: 90%;
        margin-top: 10px;
    }
}

