.about-us-container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.about-us-content-container{
    height: auto;
    width: 98%;
    padding: 1%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
}

.about-gamer-bros{
    height: auto;
    width: 50%;
    margin-bottom: 50px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    color: #f8f8f8;
    line-height: 1.5rem;
}

.person-tab{
    padding: 0.5%;
    height: 85vh;
    width: 31.3%;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
}
.person-tab::-webkit-scrollbar{
    display: none;
}

.person-img-container{
    height: 200px;
    width: 200px;
    border-radius: 50% 50% 50% 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.person-img-container img{
    height: 100%;
    width: 100%;
    border-radius: 50% 50% 50% 50%;
    object-fit: cover;
    object-position: center;
}


.about-us-heading{
    font-size: 2.5rem;
    font-weight: 600;
    color: #FFE74C;
    margin-bottom: 20px;
}

.about-us-subheading{
    font-size: 1.5rem;
    font-weight: 600;
    color: #FFE74C;
    margin-bottom: 20px;
    margin-top: 15px;
}

.about-us-text{
    font-size: 0.95rem;
    font-weight: 400;
    line-height: 24px;
    color: #f8f8f8;
    margin-bottom: 20px;
    text-align: left;
}

/* For mobile devices */
@media only screen and (max-width: 600px) {

    .about-gamer-bros{
        height: auto;
        width: 90%;
        padding: 1%;
        margin-bottom: 50px;
        text-align: center;
        font-size: 0.9rem;
        font-weight: 500;
        color: #f8f8f8;
        line-height: 1.5rem;
    }

    .about-us-content-container{
        height: auto;
        width: 98%;
        padding: 1%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        flex-wrap: wrap;
        align-items: center;
    }

    .person-tab{
        padding: 0.5%;
        height: 85vh;
        width: 98%;
        border-radius: 10px 10px 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow-y: auto;
        margin-bottom: 5px;
    }

}