.write-review-page-container{
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    background-color: #080808;
    color: #f8f8f8;
}

.review-page-ad-slot{
    flex: 1;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.review-write-up-form-container{
    flex: 3;
    padding: 1%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.review-game-img-container{
    height: 275px;
    width: 100%;
    margin-bottom: 25px;
    aspect-ratio: 4/3;
    border-radius: 10px 10px 10px 10px;
}

.review-game-img-container img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px 10px 10px 10px;
}

.review-game-name{
    height: auto;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}
.review-game-name p{
    font-size: 1.5rem;
    font-weight: 600;
    color: #f8f8f8;
}

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .write-review-page-container{
        min-height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: #080808;
        color: #f8f8f8;
    }

    .review-game-img-container{
        height: 175px;
        width: 100%;
        margin-bottom: 10px;
        aspect-ratio: 4/3;
        border-radius: 10px 10px 10px 10px;
    }

    .review-game-name{
        margin-bottom: 30px;
    }

    .review-game-name p{
        font-size: 0.95rem;
        font-weight: 600;
        color: #f8f8f8;
    }
}