.user-review-container{
    height: auto;
    width: 100%;
    box-sizing: border-box;
    margin: 10px;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
    background-color: #080808;
    box-shadow: 0 0 2.5px 0.5px #f8f8f8;
}

.spoiler-prompt-div{
    height: 40px;
    width: 100%;
    /* box-sizing: border-box;
    padding: 1%; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.spoiler-alert-div{
    height: 100%;
    width: 150px;
    border-radius: 5px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.95rem;
    font-family: 'Paciencia';
    letter-spacing: 1.25px;
    font-weight: 600;
    color: #f8f8f8;
    background-color: #FF0000;
}
.review-recommend-div{
    height: 100%;
    width: 150px;
    border-radius: 5px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.95rem;
    font-family: 'Paciencia';
    letter-spacing: 1.2px;
    font-weight: 500;
    color: #080808;
    background-color: #FFE74C;
}
.review-console-played-div{
    height: 100%;
    width: auto;
    box-sizing: border-box;
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.95rem;
    font-family: 'Paciencia';
    letter-spacing: 1.2px;
    font-weight: 500;
    color: #f8f8f8;
    background-color: transparent;
}
.review-console-played-div button{
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 0.95rem;
    font-family: 'Paciencia';
    letter-spacing: 1.2px;
    font-weight: 500;
    color: #f8f8f8;
    cursor: pointer;
}

.review-score-and-share-div{
    height: 24px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.review-score-div{
    font-size: 1.5rem;
    font-family: 'Paciencia';
    font-weight: 600;
    color: #FFE74C;
}

.review-user-data-div{
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding: 2%;
    display: flex;
    gap: 7.5px;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}
.review-user-pfp-box{
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFE74C;
    color: #080808;
    font-size: 0.95rem;
    font-family: 'Paciencia';
    font-weight: 500;
}
.review-user-pfp-box img{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}
.review-user-data{
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
}
.review-user-name{
    font-size: 1rem;
    color: #f8f8f8;
    font-family: 'Paciencia';
    font-weight: 500;
    letter-spacing: 1px;
    padding: 0;
    margin: 0;
}
.review-date-time{
    font-size: 0.7rem;
    color: #d4d4d4;
    font-family: 'Paciencia';
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.review-para-div{
    height: auto;
    box-sizing: border-box;
    padding-left: 2%;
    padding-right: 2%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 30px;
    word-spacing: 1.5px;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: 'Paciencia';
    color: #f8f8f8;
    text-align: left;
}

.review-action-bar-div{
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.review-like-dislike-div{
    height: auto;
    width: 50%;
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
}
.review-like-div{
    height: auto;
    width: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.review-dislike-div{
    height: auto;
    width: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.review-menu-icon-div{
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* COMMENT AREA */
.review-box-comments-area{
    height: 100%;
    width: 98%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
}
.review-box-comments-area::-webkit-scrollbar{
    display: none;
}
/* COMMENT AREA */

/* DELETION BOX */
.game-rev-box-deletion-content{
    background-color: #191917;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.game-rev-box-deletion-content p{
    font-size: 1.2rem;
    font-weight: 700;
    color: #f8f8f8;
    margin-bottom: 7px;
    text-align: center;
}
.game-rev-del-box-action-row{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-rev-del-btn{
    height: auto;
    width: auto;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    padding-left: 3%;
    padding-right: 3%;
    background-color: #FFE74C;
    border-radius: 5px 5px 5px 5px;
    color: #080808;
    font-size: 0.9rem;
    font-weight: 600;
    margin-right: 5px;
    cursor: pointer;
    outline: none;
    border: none;
}

.user-rev-del-btn.secondary{
    background-color: transparent;
    color: #f8f8f8;
}

.user-rev-del-btn.secondary:hover{
    background-color: #f6e573;
    color: #080808;
    cursor: pointer;
}
/* DELETION BOX */

/* FOR MOBILE DEVICES ONLY*/
@media only screen and (max-width: 600px) {
    .user-review-container{
        height: auto;
        width: 96%;
        box-sizing: border-box;
        /* padding: 2%; */
        margin-bottom: 10px;
        /* background-color: #070706; */
        background-color: transparent;
        border-radius: 5px 5px 5px 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        /* box-shadow: 0 0 2px #f8f8f8; */
    }

    .spoiler-prompt-div{
        height: auto;
        width: 100%;
        /* box-sizing: border-box;
        padding: 1%; */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .spoiler-alert-div{
        height: 50px;
        width: 100%;
    }
    .review-recommend-div{
        height: 50px;
        width: 100%;
        margin-bottom: 10px;
    }

    .rev-reply-btn{
        border: none;
        outline: none;
        background-color: transparent;
        color: #FFE74C;
        font-weight: 700;
        font-size: 0.75rem;
        cursor: pointer;
        margin: 7px;
    }
}
/* FOR MOBILE DEVICES ONLY */