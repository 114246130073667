.navbar-toolbar{
    background-color: #080808;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-container{
    /* background-color: mediumpurple; */
    height: 50px;
    width: auto;
    display: flex;
    gap: 10px;
    align-items: center;
}
.logo-img-container{
    height: 30px;
    width: 85px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.logo-img-container img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
    padding: 0;
    margin: 0;
}
.company-name-txt{
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    font-family: 'Pancienca', sans-serif;
    color: #f8f8f8;
    cursor: pointer;
}

.options-container{
    /* background-color: mediumvioletred; */
    width: auto;
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
}

.profile-container{
    width: auto;
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
}

.signed-in-user-pfp-container{
    height: 28px;
    width: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffe74c;
}
.signed-in-user-pfp-container img{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}
.signed-in-user-txt{
    font-size: 0.9rem;
    font-weight: 500;
    color: #080808;
    text-align: center;
}
.navbar-search-field{
    height: 27.5px;
    width: 350px;
    border: none;
    border-radius: 5px 5px 5px 5px;
    /* border-width: 1.5px 1.5px 1.5px 1.5px; */
    padding: 1%;
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;
    background-color: #191917;
    cursor: text;
}
.navbar-search-field.mobile{
    background-color: transparent;
    border: none;
    height: auto;
    width: auto;
}
.navbar-search-field.tablet{
    background-color: transparent;
    border: none;
    height: auto;
    width: auto;
}
.navbar-search-prompt-txt{
    font-size: 0.9rem;
    font-style: italic;
    font-family: 'paciencia',sans-serif;
    color: #ccc;
    text-align: left;
}
.navbar-search-prompt-txt.mobile{
    display: none;
}
.navbar-search-prompt-txt.tablet{
    display: none;
}

.drawer-content{
    background-color: #080808;
    min-height: 100vh;
    width: 50vw;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
}
.notification-drawer-content{
    box-sizing: border-box;
    background-color: #080808;
    min-height: 100vh;
    width: 35vw;
    padding: 2%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
}
.notification-drawer-content.mobile{
    width: 100vw;
}
.notification-drawer-heading{
    height: auto;
    width: 100%;
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
}
.fetching-notification-prompt{
    height: auto;
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.loading-notification-prompt-txt{
    font-size: 24px;
    color: #f8f8f8;
    font-family: 'Paciencia',sans-serif;
    text-align: center;

}