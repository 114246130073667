.full-review-container{
    height: auto;
    width: 100%;
    background-color: #080808;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
}

.full-review-container::-webkit-scrollbar{
    display: none;
}

.review-user-details-container{
    height: auto;
    width: 50%;
    padding: 1%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.review-user-details-pfp-box{
    height: 50px;
    width: 50px;
    margin-right: 7px;
    background-color: #FFE74C;
    border-radius: 50% 50% 50% 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.review-user-details-pfp-box p{
    font-size: 1.25rem;
    font-weight: 500;
    color: #080808;
}
.review-user-details-pfp-box img{
    height: 100%;
    width: 100%;
    border-radius: 50% 50% 50% 50%;
    object-fit: cover;
}

.review-user-btn{
    background-color: transparent;
    margin-right: 7px;
    border: none;
    outline: none;
    font-size: 1.5rem;
    font-weight: 700;
    color: #FFE74C;
    cursor: pointer;
}

.review-user-details-container-p{
    font-size: 1.2rem;
    font-weight: 500;
    color: #f8f8f8;
}

.full-review-game-details-container{
    height: auto;
    width: 50%;
    padding: 1%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.full-review-game-pfp-box{
    height: 150px;
    width: 100px;
    margin-right: 15px;
    background-color: #FFE74C;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.full-review-game-pfp-box img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px 10px 10px 10px;
}

.full-review-game-details-txts{
    height: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.full-review-spoiler-and-recommendations{
    height: 25px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}

.full-review-spoiler-prompt{
    height: 25px;
    width: auto;
    box-sizing: border-box;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 25px 25px 25px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.95rem;
    font-weight: 500;
    color: #080808;
    background-color: #ff0000;
}

.full-review-recommendation-prompt{
    height: 25px;
    width: auto;
    box-sizing: border-box;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 25px 25px 25px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.95rem;
    font-weight: 500;
    color: #080808;
    background-color: #ffe74c;
}

.full-review-game-name{
    background-color: transparent;
    border: none;
    outline: none;
    text-align: left;
    font-size: 1.5rem;
    font-weight: 700;
    color: #FFE74C;
    /* margin-bottom: 7px; */
    cursor: pointer;
    margin: 0;
    padding: 0;
}

.full-game-review-score{
    margin: 0;
    padding: 0;
    font-size: 3.5rem;
    font-weight: 700;
    color: #f8f8f8;
    /* margin-bottom: 7px; */
}

.final-review-time-elapsed{
    font-size: 0.85rem;
    color: #c3c3c3;
    width: 100%;
    text-align: left;
    margin: 0;
    padding: 0;
    font-style: italic;
}

.full-review-game-console-div{
    height: auto;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #f8f8f8;
    font-weight: 600;
    font-size: 0.95rem;
}
/* .full-review-gaming-console{
    color: #f8f8f8;
    font-weight: 600;
    font-size: 0.95rem;
} */

.full-game-review-text{
    width: 50%;
    padding: 1%;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5;
    color: #f8f8f8;
    text-align: left;
}

.full-review-action-container{
    height: auto;
    width: 50%;
    padding: 1%;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
}

/* COMMENT AREA */
.review-comments-area{
    height: 100%;
    width: 50%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
}
.review-comments-area::-webkit-scrollbar{
    display: none;
}
/* COMMENT AREA */

/* LOADERS */
.full-review-user-loaders{
    height: auto;
    width: 50%;
    padding: 1%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.review-user-details-pfp-loader{
    height: 50px;
    width: 50px;
    margin-right: 7px;
    background-color: #7a7a7a;
    border-radius: 50% 50% 50% 50%;
}

.review-user-btn-loader{
    height: 1.2rem;
    width: 200px;
    background-color: #7a7a7a;
    border-radius: 15px 15px 15px 15px;
    margin-right: 7px;
}

.reaction-rev-area-btn{
    height: auto;
    width: 55px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: transparent;
    margin-right: 7px;
    border: none;
    outline: none;
    cursor: pointer;
}

.full-review-game-details-loader{
    height: auto;
    width: 50%;
    padding: 1%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/* LOADERS */

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .full-review-container{
        height: 100%;
        width: 100%;
        background-color: #080808;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .review-user-details-container{
        height: auto;
        width: 96%;
        padding: 2%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
    }

    .review-user-details-pfp-box{
        height: 35px;
        width: 35px;
        margin-right: 7px;
        background-color: #FFE74C;
        border-radius: 50% 50% 50% 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .review-user-btn{
        background-color: transparent;
        margin-right: 7px;
        border: none;
        outline: none;
        font-size: 0.95rem;
        font-weight: 700;
        color: #FFE74C;
        cursor: pointer;
    }

    .review-user-details-container-p{
        font-size: 0.85rem;
        font-weight: 500;
        color: #f8f8f8;
    }

    .full-review-game-details-container{
        height: auto;
        width: 96%;
        padding: 1%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .full-review-game-console-div{
        margin-top: 10px;
        margin-bottom: 10px;
        height: auto;
        width: 96%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .full-game-review-text{
        width: 98%;
        padding: 1%;
        font-size: 0.95rem;
        font-weight: 400;
        line-height: 1.5;
        color: #f8f8f8;
        text-align: left;
    }

    .full-review-action-container{
        height: auto;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
    }

    .review-comments-area{
        height: 100%;
        width: 98%;
        padding: 2%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow-y: auto;
    }
}