.trending-review-carousel{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #080808;
    position: relative;
}
.trending-review-carousel-nav-btns{
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    outline: none;
}
.trending-review-carousel-nav-btns.left{
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    outline: none;
}
.trending-review-carousel-nav-btns.right{
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    outline: none;
}

.review-carousel-content{
    display: flex;
    overflow: hidden;
    width: 100%;
}

.review-carousel-item{
    min-width: 100%;
    transition: transform 0.5s ease-in-out;
    opacity: 0;
    display: none;
    box-sizing: border-box;
}

.review-carousel-item.active{
    opacity: 1;
    display: block;
}

@media only screen and (max-width: 600px) {
    .trending-review-carousel{
        width: 100%;
    }

    .trending-review-carousel-nav-btns {
        /* top: 10px; */
        transform: none;
        position: absolute;
        top: 61.5%;
        transform: translateY(-50%);
        z-index: 10;
    }

    .trending-review-carousel-nav-btns.left {
        display: none;
    }

    .trending-review-carousel-nav-btns.right {
        right: 10px;
    }

    .review-carousel-content {
        min-width: 100vw;
    }
}