.articles-home-container{
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.article-ad-component-1{
    min-height: 100vh;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f8f8f8;
}
.article-ad-component-2{
    min-height: 100vh;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f8f8f8;
}

.main-article-list-content-area{
    height: 100%;
    flex: 2;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.article-list-heading{
    font-size: 2.5rem;
    font-weight: 700;
    color: #f8f8f8;
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
}
.article-link-text{
    text-decoration: none;
    display: block;
    width: 100%;
}
.article-list-container{
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding: 1%;
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    justify-content: flex-start;
    align-items: center;
}

.article-list-actions{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.article-list-load-more-cta{
    height: auto;
    width: auto;
    padding: 1%;
    padding-left: 3%;
    padding-right: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    outline: none;
    color: #FFE74C;
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer;
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
    .article-home-container{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
    }

    .article-ad-component-1{
        display: none;
    }

    .article-ad-component-2{
        display: none;
    }
}