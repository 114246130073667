.article-read-page{
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: #f8f8f8;
}
.article-read-space-div{
    margin-top: 10px;
    margin-bottom: 10px;
}
.article-read-ad-component-1{
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.article-read-ad-component-2{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.article-relevant-items-area{
    flex: 1;
    box-sizing: border-box;
    padding: 1%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.article-relevant-games-consoles-area{
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.article-read-relevant-games-area{
    height: auto;
    width: 100%;
    display: flex;
    gap: 7px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
.article-rel-games-heading{
    font-size: 0.95rem;
    font-weight: 700;
    color: #FFE74C;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
}
.article-rel-game-tile{
    height: 100px;
    width: 100px;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s height ease-in-out, 0.3s width ease-in-out;
}
.article-rel-game-tile img{
    height: 100%;
    width: 100%;
    aspect-ratio: 2/3;
    border-radius: 5px 5px 5px 5px;
    object-fit: cover;
}
.article-rel-game-tile:hover{
    height: 125px;
    width: 125px;
    cursor: pointer;
}

.article-read-relevant-consoles-area{
    height: auto;
    width: 100%;
    display: flex;
    gap: 7px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.article-rel-console-tile{
    height: 100px;
    width: 150px;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s height ease-in-out, 0.3s width ease-in-out;
}
.article-rel-console-tile img{
    height: 100%;
    width: 100%;
    aspect-ratio: 2/3;
    border-radius: 5px 5px 5px 5px;
    object-fit: contain;
}
.article-rel-console-tile:hover{
    height: 125px;
    width: 125px;
    cursor: pointer;
}

.main-article-read-area{
    height: 100%;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.article-banner-container{
    height: 35vh;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.article-banner-bg-container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.article-banner-bg-container img{
    height: 100%;
    width: 100%;
    aspect-ratio: 2/3;
    object-fit: cover;
}
.article-banner-overlay{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 2%;
    position: absolute;
    background-color: #08080885;
    mix-blend-mode: hard-light;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.article-banner-title{
    margin: 0;
    padding: 0;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.5rem;
}
.article-banner-title p{
    text-align: left;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
}
.article-banner-date{
    width: 100%;
    font-size: 0.9rem;
    font-weight: 600;
    color: #f8f8f8;
    margin: 0;
    padding: 0;
    text-align: left;
}
.article-para-texts{
    box-sizing: border-box;
    margin: 0;
    padding: 2%;
    height: auto;
    width: 100%;
    text-align: left;
    line-height: 30px;
    font-size: 0.95rem;
}

.article-para-texts img{
    height: auto;
    width: 100%;
    object-fit: contain;
    padding: 0;
    margin: 0;
}

.article-options-div{
    margin-top: 10px;
    margin-bottom: 10px;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.article-sharing-area{
    box-sizing: border-box;
    padding: 2%;
    margin-top: 15px;
    height: auto;
    width: 100%;
    display: flex;
    gap: 25px;
    justify-content: flex-start;
    align-items: center;
}

.article-sharing-text{
    font-size: 0.85rem;
    color: #c1c1c1;
    font-style: italic;
}

.article-fb-share-container{
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.article-fb-share-container img{
    height: 100%;
    width: 100%;
    aspect-ratio: 2/3;
    object-fit: contain;
}
.article-linked-in-share-container{
    height: 24px;
    width: 24px;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.article-linked-in-share-container img{
    height: 100%;
    width: 100%;
    aspect-ratio: 2/3;
    object-fit: contain;
}
.article-sharing-a{
    display: block;
    text-decoration: none;
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
    .article-read-page{
        min-height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: flex-start;
        align-items: flex-start;
        color: #f8f8f8;
    }

    .article-para-texts{
        padding: 3%;
    }

    .article-read-ad-component-1{
        display: none;
    }

    .article-read-ad-component-2{
        display: none;
    }

    .article-read-relevant-games-area{
        box-sizing: border-box;
        padding: 2%;
    }

    .article-relevant-items-area{
        box-sizing: border-box;
        padding: 2%;
    }
}