.game-search-txt-input{
    height: 35px;
    width: 98%;
    padding: 1%;
    padding-left: 2%;
    padding-right: 2%;
    background-color: transparent;
    border: solid #f8f8f8;
    border-radius: 10px 10px 10px 10px;
    color: #f8f8f8;
    font-size: 0.95rem;
    font-weight: 500;
}