.forum-comment{
    height: auto;
    width: 96%;
    padding: 2%;
    margin-left: 1%;
    margin-top: 10px;
    /* background-color: mediumslateblue; */
    border-left: solid #FFE74C;
    border-left-width: 2.5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.forum-comment-date{
    font-size: 0.7rem;
    font-weight: 600;
    color: #bfbfbf;
}

.forum-comment-content{
    margin-top: 7px;
    margin-bottom: 10px;
    font-size: 0.9rem;
    font-weight: 500;
    color: #f8f8f8;
    text-align: left;
}

.forum-comment-actions{
    height: auto;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* background-color: mediumvioletred; */
}

.comment-posting-error-prompt{
    color: #f8f8f8;
    width: 100%;
    text-align: center;
}

.comment-show-replies{
    margin-right: 7px;
    font-size: 0.8rem;
    font-weight: 700;
    color: #FFE74C;
    margin-right: 7px;
    cursor: pointer;
}

.comment-replying{
    margin-left: 20px;
    margin-right: 7px;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    padding-left: 1.5%;
    padding-right:1.5%;
    border-radius: 25px 25px 25px 25px;
    font-size: 0.8rem;
    font-weight: 700;
    color:#080808;
    background-color: #FFE74C;
    margin-right: 7px;
    cursor: pointer;
}

.comment-text-input-area{
    height: auto;
    width: 100%;
    margin-bottom: 7px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.comment-reaction-box{
    /* margin-right: 7px; */
    /* background-color: maroon; */
    padding: 0.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.comment-reaction-box p{
    margin: 0;
    font-size: 0.9rem;
    font-weight: 700;
}

.comment-reporting{
    font-size: 0.9rem;
    font-weight: 700;
    color: #f8f8f8;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
}

.text-input-user{
    /* background-color: mediumpurple; */
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.text-input-user-txt{
    min-width: 10%;
    text-align: left;
    margin-left: 5px;
    font-size: 0.8rem;
    font-weight: 600;
    color: #f8f8f8;
    overflow-wrap: break-word;
}

