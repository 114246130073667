.footer-container{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.company-copyright-info{
    height: auto;
    width: auot;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.company-copyright-info p{
    color: #f8f8f8;
}

.footer-txt{
    text-align: left;
    font-size: 0.95rem;
    font-weight: 500;
    color: #f8f8f8;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
}

.company-social-handles{
    height: 100%;
    width: auto;
    padding: 1.5%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.social-handle{
    height: 30px;
    width: 30px;
    margin: 10px;
    cursor: pointer;
}
.social-handle img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}


/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .footer-container{
        height: auto;
        width: 98%;
        padding: 1%;
        background-color: #191917;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 5px;
    }

    .footer-txt{
        font-size: 0.85rem;
        font-weight: 700;
        margin-top: 7px;
        margin-bottom: 7px;
        color: #f8f8f8;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .company-social-handles{
        height: 100%;
        width: auto;
        padding: 1.5%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .company-copyright-info p{
        color: #f8f8f8;
    }

    .social-handle{
        height: 25px;
        width: 25px;
        margin: 7px;
        cursor: pointer;
    }
}

/* For tablets */
@media only screen and (min-width: 600px) and (max-width: 1279px){
    .footer-container{
        height: auto;
        width: 98%;
        padding: 1%;
        background-color: #191917;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 5px;
    }

    .company-copyright-info{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }
    .company-copyright-info p{
        color: #f8f8f8;
    }

    .footer-txt{
        font-weight: 500;
        margin-bottom: 10px;
    }
}