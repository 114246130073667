/* styling for discussion page */
.discussion-page-container{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #080808;
}
.discussion-page-tile-container{
    height: auto;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 15px; */
}
.discussion-comments-area{
    height: 100%;
    width: 70%;
    padding: 2%;
    /* background-color: mediumvioletred; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
}
.discussion-comments-area::-webkit-scrollbar{
    display: none;
}

.discussion-comments-container{
    position: sticky;
    background-color: #191917;
    height: auto;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: auto;
}

/* For mobile devices */
@media only screen and (max-width: 600px) {
    /* .discussion-comments-container{
        position: sticky;
        background-color: #191917;
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-x: auto;
    } */

    .discussion-comments-area{
        height: 100%;
        width: 95%;
        padding: 2%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow-y: auto;
    }
}