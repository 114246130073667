.editor-pick-container{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    z-index:1;
    /* background-color: #191918; */
}

.editor-pick-container-heading{
    font-size: 1.7rem;
    font-weight: 800;
    color: #FFE74C;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-left: 2%;
    z-index: 2;
}

.editor-picks-area{
    height: auto;
    width: 96%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 1;
}    

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
}

/* For tablets */
@media only screen and (min-width: 600px) and (max-width: 1279px){
    .editor-pick-container-heading{
        width: 100%;
    }
}