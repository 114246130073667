.user-comment-deletion-container{
    background-color: #191917;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #f8f8f8;
    font-size: 1.2rem;
    font-weight: 600;
}
.user-comment-deletion-actions{
    background-color: #191917;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #f8f8f8;
    font-size: 1.2rem;
    font-weight: 600;
}

.user-comment-deletion-button{
    height: 30px;
    width: 178px;
    margin-bottom: 10px;
    /* margin-left:27%; */
    border-radius: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 600;
    color: #080808;
    background-color: #FFE74C;
    cursor: pointer;
    border: none;
}

.user-comment-deletion-cancel-button{
    height: 30px;
    width: 45px;
    margin-bottom: 10px;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 600;
    color: #FFE74C;
    background-color: transparent;
    cursor: pointer;
    border: none;
}
.user-comment-deletion-cancel-button:hover{
    height: 30px;
    width: 178px;
    color: #080808;
    background-color: #ffe74cb3;
}